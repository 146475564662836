import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import "primereact/resources/themes/lara-light-cyan/theme.css"
import "../../../../../assets/css/Datatable.css"
import "../../../../../assets/css/AdminPages.css"
import MedicationContext from '../../orgContext/medication/MedicationContext'
import Spinner from '../../../../shared/Spinner'

/* RBT Components */

/* icons */
import { TiEdit } from "react-icons/ti"
import { MdOutlineDeleteSweep } from "react-icons/md"
import { LuEye } from "react-icons/lu"

const OrgMedicineIndex = ({ title, ...props }) => {
  const { medications, loading, deleteMedication } = useContext(MedicationContext)


  const verifiedBodyTemplate = (dataItem) => {
    return (
      <div className="d-flex align-items-center gap-2">
        <Link to={`/organization/medicine/edit/${dataItem.medication_id}`} className='cta-icons'>
          <TiEdit size={30} color='darkGrey' />
        </Link>
        <button href="#" className='cta-icons border-0 bg-transparent'>
          <MdOutlineDeleteSweep size={30} color='darkGrey' onClick={() => deleteMedication(dataItem.medication_id)} />
        </button>
      </div>
    )
  }

  if (!loading && (!medications || medications.length === 0)) {
    return <p>No Medication</p>
  }
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="card bg-white shadow border-0">
            <div className="card-body p-0">
              {loading ? (
                <Spinner />
              ) : (
                <DataTable value={medications} paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}>
                  <Column field="name" header="Name"></Column>
                  <Column field="description" header="Description"></Column>
                  <Column field="action" header="Action" dataType="boolean" style={{ minWidth: '6rem' }} body={verifiedBodyTemplate} />
                </DataTable>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default OrgMedicineIndex