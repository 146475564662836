import { useEffect } from "react"
import Header from "./admin-nav/Header"
import Sidebar from "./admin-nav/Sidebar"

function MainApp({ title, Component }) {
  useEffect(() => {
    document.title = `${title} - MedicSnow`;
  }, [title])
  return (
    <div className="main-admin">
      <Sidebar />
      <Header />
      <div className="admin-content">
        <div className="container-fluid px-5">
          {Component && <Component title={title} />}
        </div>
      </div>
    </div>
  )
}
export default MainApp