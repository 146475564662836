import { useEffect, useState } from "react";
import "./Auth.css";

/* images */
import loginBg from "../../../assets/images/auth/auth-bg-1.png";

function OrgUserLogin() {
  useEffect(() => {
    document.title = "Login - MedicSnow";
  }, []);

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("http://localhost:4000/organizationUsers");
      const data = await response.json();
      const user = data.find((u) => u.email === email);

      if (user && user.password === password) {
        console.log(`Logged in successfully`);
        localStorage.setItem("token", true);
        localStorage.setItem("role", user.role);
        const permissions = JSON.stringify(user.permissions);
        localStorage.setItem("permissions", permissions);
        window.location.href = "http://localhost:3000/organization/dashboard";
      } else {
        setError("Invalid email or password");
      }
    } catch (error) {
      console.error("Error logging in:", error);
      setError("Error logging in. Please try again later.");
    }
  };

  return (
    <div className="vh-100">
      <div className="auth d-flex flex-column flex-lg-row flex-column-fluid">
        <div className="login-aside text-center d-flex flex-column">
          <div className="d-flex flex-column">
            <div className="text-center mb-4 pt-5">
              <img alt="MedicSnow Solutions" width="200" />
            </div>
            <h3 className="mb-2">Welcome back!</h3>
            <p>
              User Experience & Interface Design <br />
              Strategy SaaS Solutions
            </p>
          </div>
          <div
            className="login-aside__image"
            style={{ backgroundImage: `url(${loginBg})` }}
          ></div>
        </div>
        <div className="container d-flex flex-column justify-content-center position-relative overflow-hidden mx-auto">
          <div className="d-flex justify-content-center h-100 align-items-center">
            <div className="auth-content">
              <div className="row g-0">
                <div className="col-xl-12">
                  <div className="auth-content__form">
                    <form onSubmit={handleSubmit}>
                      <h3 className="text-center text-black mb-4">
                        Sign in your account
                      </h3>
                      <div className="mb-3">
                        <label className="mb-1" for="val-email">
                          <strong>Email</strong>
                        </label>
                        <div>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Type Your Email Address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="mb-1" for="val-email">
                          <strong>Password</strong>
                        </label>
                        <div>
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Type Your Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="d-grid mt-4">
                        <button type="submit" className="btn btn-primary">
                          Sign In
                        </button>
                      </div>
                      {error && <div className="text-danger mt-2">{error}</div>}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrgUserLogin;
