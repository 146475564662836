import { createContext, useEffect, useReducer } from 'react'
import { useNavigate } from 'react-router-dom'
import protocolReducer from './ProtocolReducer'
import PropTypes from 'prop-types'


const ProtocolContext = createContext()

const MEDIC_URL = process.env.REACT_APP_MEDIC_URL
const MEDIC_TOKEN = process.env.REACT_APP_MEDIC_TOKEN

export const ProtocolProvider = ({ children }) => {
  const navigate = useNavigate()
  const initialState = {
    loading: true,
    protocol: [],
    protocolWizard: [],
    protocolEdit: {
      item: {},
      edit: false,
    },
    wizardProtocolEdit: {
      item: {},
      edit: false,
    },
  }
  const [state, dispatch] = useReducer(protocolReducer, initialState)

  useEffect(() => {
    fetchProtocols()
    fetchWizardProtocols()
  }, [])

  /* Fetch Protocols */
  const fetchProtocols = async () => {
    const response = await fetch(`${MEDIC_URL}/protocols`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${MEDIC_TOKEN}`,
      }
    })
    const data = await response.json()
    dispatch({ type: 'SET_PROTOCOL', payload: data })
  }

  /* Fetch Wizard Protocols */
  const fetchWizardProtocols = async () => {
    const response = await fetch(`http://localhost:4000/wizardProtocol`, {
      method: 'GET',
      headers: {
        // Authorization: `Bearer ${MEDIC_TOKEN}`,
        'Content-Type': 'application/json',
      }
    })
    const data = await response.json()
    // console.log(data)
    dispatch({ type: 'GET_WIZARD_PROTOCOL', payload: data })
  }

  /* Add Protocol */

  const addProtocol = async (newProtocol) => {
    const response = await fetch(`${MEDIC_URL}/protocols`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${MEDIC_TOKEN}`
      },
      body: JSON.stringify(newProtocol),
    })

    if (response.ok) {
      const data = await response.json()
      dispatch({ type: 'ADD_PROTOCOL', payload: data })
      fetchProtocols()
    } else {
      console.error(`Error Adding protocol: ${response.statusText}`)
    }
  }

  /* Add Wizard Protocol */
  const addWizardProtocol = async (newWizardProtocol) => {
    const response = await fetch(`http://localhost:4000/wizardProtocol`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newWizardProtocol),
    })

    if (response.ok) {
      navigate('/organization/protocol/index')
    } else {
      console.error(`Error Adding protocol: ${response.statusText}`)
    }
  }

  /* Edit Wizard Protocol */
  const editWizardProtocol = async (id) => {
    // console.log('edit wizard:', id)
    const response = await fetch(`http://localhost:4000/wizardProtocol`, {
      method: 'GET',
      headers: {
        // Authorization: `Bearer ${MEDIC_TOKEN}`,
        'Content-Type': 'application/json',
      }
    })
    const data = await response.json()
    const wizardData = data.find(wizard => wizard.protocol_id === id)
    // console.log('edit wizard:', wizardData)
    dispatch({ type: 'EDIT_WIZARD_PROTOCOL', payload: wizardData })
  }

  /* Delete Protocol */
  const deleteProtocol = async (id) => {
    if (window.confirm(`Are you sure you want to delete?`)) {
      await fetch(`${MEDIC_URL}/protocols/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${MEDIC_TOKEN}`,
        }
      })
      dispatch({ type: 'DELETE_PROTOCOL', payload: id })
      fetchProtocols()
    }
  }


  /* Update Protocol */
  const updateProtocol = async (id, updItem) => {
    const response = await fetch(`${MEDIC_URL}/protocols/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${MEDIC_TOKEN}`,
      },
      body: JSON.stringify(updItem),
    })

    if (response.ok) {
      const updatedData = await response.json()
      dispatch({ type: 'UPDATE_PROTOCOL', payload: { id, updatedData } })
      fetchProtocols()
    } else {
      console.error(`Error updating protocol: ${response.statusText}`)
    }
  }

  /* Edit Protocol */
  const editProtocol = (item) => {
    dispatch({ type: 'EDIT_PROTOCOL', payload: item })
  }

  return (
    <ProtocolContext.Provider
      value={{
        protocol: state.protocol,
        protocolWizard: state.protocolWizard,
        loading: state.loading,
        protocolEdit: state.protocolEdit,
        wizardProtocolEdit: state.wizardProtocolEdit,
        addProtocol,
        addWizardProtocol,
        editProtocol,
        updateProtocol,
        deleteProtocol,
        editWizardProtocol
      }}>
      {children}
    </ProtocolContext.Provider>
  )
}

ProtocolProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ProtocolContext