import { useState } from "react";
import {
  Card,
  Button,
  Form,
  Row,
  Col,
  FormControl,
  FormGroup,
  FormCheck,
  InputGroup,
  Modal,
  Alert,
  ProgressBar,
} from "react-bootstrap";

import Mastercard from "../../../../../assets/images/cards/mastercard.png";
import visa from '../../../../../assets/images/cards/visa.png'

import '../../../../../assets/css/PaymentPlan.css'

const PaymentPlan = () => {
  const [paymentMethod, setPaymentMethod] = useState("card");
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  return (
    <>
      <Card className="mb-4 rounded p-4 bg-white shadow-sm rounded">
        {/* Current Plan */}
        <h5>Current Plan</h5>
        <Card.Body>
          <Row>
            <Col md={6} className="mb-1">
              <div className="mb-3">
                <h6 className="mb-1">Your Current Plan is Basic</h6>
                <p>A simple start for everyone</p>
              </div>
              <div className="mb-3">
                <h6 className="mb-1">Active until Dec 09, 2023</h6>
                <p>
                  We will send you a notification upon Subscription expiration
                </p>
              </div>
              <div className="mb-3">
                <h6 className="mb-1 d-flex align-items-center">
                  <span className="me-2">$199 Per Month</span>
                  <span className="badge bg-label-primary">Popular</span>
                </h6>
                <p>Standard plan for small to medium businesses</p>
              </div>
            </Col>
            <Col md={6} className="mb-1">
              <Alert variant="warning" className="mb-3">
                <Alert.Heading className="mb-1">
                  We need your attention!
                </Alert.Heading>
                <span>Your plan requires update</span>
              </Alert>
              <div className="plan-statistics">
                <div className="d-flex justify-content-between mb-2">
                  <h6>Days</h6>
                  <h6>24 of 30 Days</h6>
                </div>
                <ProgressBar
                  now={75}
                  label="6 days remaining until your plan requires update"
                  className="mt-1 mb-0"
                />
              </div>
            </Col>
            <Col xs={12}>
              <Button
                variant="primary"
                className="me-2 mt-2 waves-effect waves-light"
                data-bs-toggle="modal"
                data-bs-target="#pricingModal"
              >
                Upgrade Plan
              </Button>
              <Button variant="label-danger cancel-subscription mt-2 waves-effect">
                Cancel Subscription
              </Button>
            </Col>
          </Row>
        </Card.Body>
        {/* /Current Plan */}
      </Card>
      <Card className="mb-4 rounded p-4 bg-white shadow-sm rounded">
        <h5>Payment Method</h5>
        <Card.Body>
          <Row>
            <Col md={6}>
              <Row className="mb-2">
                <Col lg={12}>
                  <Form>
                    <FormGroup>
                      <Form.Check
                        inline
                        type="radio"
                        label="Credit/Debit/ATM Card"
                        checked={paymentMethod === "card"}
                        onChange={() => setPaymentMethod("card")}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="COD/Cheque"
                        checked={paymentMethod === "cod"}
                        onChange={() => setPaymentMethod("cod")}
                      />
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
              {paymentMethod === "card" && (
                <Row>
                  <Col>
                    <Form>
                      <Row className="mb-5 ">
                        <Form.Group as={Col}>
                          <Form.Label>Card Number</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="0000 0000 0000 0000"
                          />
                        </Form.Group>
                      </Row>

                      <Row className="mb-3 mt-8">
                        <Form.Group as={Col}>
                          <Form.Label>Name on Card</Form.Label>
                          <Form.Control type="text" placeholder="John Doe" />
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label>Expiry Date</Form.Label>
                          <Form.Control type="text" placeholder="MM/YY" />
                        </Form.Group>

                        <Form.Group as={Col}>
                          <Form.Label>CVC</Form.Label>
                          <Form.Control type="text" placeholder="123" />
                        </Form.Group>
                      </Row>

                      <Form.Group className="mb-3" id="saveCardCheckbox">
                        <Form.Check
                          type="switch"
                          label="Save Card for future billing?"
                        />
                      </Form.Group>
                    </Form>
                  </Col>
                </Row>
              )}
            </Col>
            <Col md={6}>
              <div className="mt-5 mt-md-0 rounded">
                <h6>My Cards</h6>
                <div className="added-cards">
                  <Card className="bg-lighter p-3 rounded mb-3 mb-4 rounded p-4 bg-white shadow-sm rounded">
                    <div className="d-flex justify-content-between flex-sm-row flex-column">
                      <div className="card-information me-2">
                        <Card.Img
                          className="mb-3 img-fluid w-25  "
                          src={Mastercard}
                          alt="Master Card"
                        />
                        <div className="d-flex align-items-center mb-2 flex-wrap gap-2">
                          <p className="mb-0 me-2">Tom McBride</p>
                          <span className="badge bg-label-primary">
                            Primary
                          </span>
                        </div>
                        <span className="card-number">∗∗∗∗ ∗∗∗∗ 9856</span>
                      </div>
                      <div className="d-flex flex-column text-start text-lg-end">
                        <div className="d-flex order-sm-0 order-1 mt-sm-0 mt-3">
                          <Button
                            variant="label-primary"
                            className="me-2 waves-effect"
                            data-bs-toggle="modal"
                            data-bs-target="#editCCModal"
                          >
                            Edit
                          </Button>
                          <Button
                            variant="label-secondary"
                            className="waves-effect"
                          >
                            Delete
                          </Button>
                        </div>
                        <small className="mt-sm-auto mt-2 order-sm-1 order-0">
                          Card expires at 12/26
                        </small>
                      </div>
                    </div>
                  </Card>
                  <Card className="bg-lighter p-3 rounded">
                    <div className="d-flex justify-content-between flex-sm-row flex-column">
                      <div className="card-information me-2">
                        <Card.Img
                          className="mb-3 img-fluid w-50"
                          src={visa}
                          alt="Visa Card"
                        />
                        <p className="mb-2">Mildred Wagner</p>
                        <span className="card-number">∗∗∗∗ ∗∗∗∗ 5896</span>
                      </div>
                      <div className="d-flex flex-column text-start text-lg-end">
                        <div className="d-flex order-sm-0 order-1 mt-sm-0 mt-3">
                          <Button
                            variant="label-primary"
                            className="me-2 waves-effect"
                            data-bs-toggle="modal"
                            data-bs-target="#editCCModal"
                          >
                            Edit
                          </Button>
                          <Button
                            variant="label-secondary"
                            className="waves-effect"
                          >
                            Delete
                          </Button>
                        </div>
                        <small className="mt-sm-auto mt-2 order-sm-1 order-0">
                          Card expires at 10/27
                        </small>
                      </div>
                    </div>
                  </Card>
                </div>
                <Modal id="editCCModal" centered>
                  <Modal.Header closeButton>
                    <Modal.Title>Edit Card</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {/* Modal content for editing card details */}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary">Update</Button>
                    <Button variant="danger" data-bs-dismiss="modal">
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </Col>
          </Row>
        </Card.Body>
        <Row>
          <Col>
            <Button variant="primary" onClick={handleShowModal}>
              Save Changes
            </Button>
            <Button variant="secondary" className="ms-2">
              Reset
            </Button>
          </Col>
        </Row>
      </Card>

      {/* Edit Card Modal */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Your modal content for editing card details */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>
            Update
          </Button>
          <Button variant="danger" onClick={handleCloseModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PaymentPlan;
