import { useState } from "react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import {
  Card,
  Button,
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";

function ChangePassword() {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  const handleClickShowCurrentPassword = () =>
    setShowCurrentPassword(!showCurrentPassword);
  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleClickShowConfirmNewPassword = () =>
    setShowConfirmNewPassword(!showConfirmNewPassword);

  return (
    <div>
      <Row className="justify-content-center">
        <Col xs={12} md={6} lg={12}>
          <Card className="mb-4 rounded p-4 bg-white shadow-sm rounded ">
            {/* <Card.Header> */}
            <h5>Change Password</h5>
            {/* </Card.Header> */}
            <Card.Body>
              <Form>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Label htmlFor="currentPassword">
                      Current Password
                    </Form.Label>
                    <InputGroup className="mb-3">
                      <FormControl
                        type={showCurrentPassword ? "text" : "password"}
                        id="currentPassword"
                        placeholder="············"
                      />
                      <Button
                        variant="outline-secondary"
                        onClick={handleClickShowCurrentPassword}
                      >
                        {showCurrentPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                      </Button>
                    </InputGroup>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Label htmlFor="newPassword">New Password</Form.Label>
                    <InputGroup className="mb-3">
                      <FormControl
                        type={showNewPassword ? "text" : "password"}
                        id="newPassword"
                        placeholder="············"
                      />
                      <Button
                        variant="outline-secondary"
                        onClick={handleClickShowNewPassword}
                      >
                        {showNewPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                      </Button>
                    </InputGroup>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Label htmlFor="confirmNewPassword">
                      Confirm New Password
                    </Form.Label>
                    <InputGroup className="mb-3">
                      <FormControl
                        type={showConfirmNewPassword ? "text" : "password"}
                        id="confirmNewPassword"
                        placeholder="············"
                      />
                      <Button
                        variant="outline-secondary"
                        onClick={handleClickShowConfirmNewPassword}
                      >
                        {showConfirmNewPassword ? (
                          <FaRegEye />
                        ) : (
                          <FaRegEyeSlash />
                        )}
                      </Button>
                    </InputGroup>
                  </Col>
                  <Col xs={12} mb={4}>
                    <h6>Password Requirements:</h6>
                    <ul className="ps-3 mb-0 mt-4">
                      <li className="mb-1">
                        Minimum 8 characters long - the more, the better
                      </li>
                      <li className="mb-1">At least one lowercase character</li>
                      <li>
                        At least one number, symbol, or whitespace character
                      </li>
                    </ul>
                  </Col>
                  <Col xs={12} className="mt-4">
                    <Button variant="primary" className="me-2" type="submit">
                      Save Changes
                    </Button>
                    <Button variant="secondary" type="reset">
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default ChangePassword;
