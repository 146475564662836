const protocolReducer = (state, action) => {
  switch (action.type) {
    case 'SET_PROTOCOL':
      return {
        ...state,
        protocol: action.payload,
        loading: false,
      }
    case 'GET_WIZARD_PROTOCOL':
      return {
        ...state,
        protocolWizard: action.payload,
        loading: false,
      }
    case 'ADD_PROTOCOL':
      return {
        ...state,
        protocol: [action.payload, ...state.protocol],
      }
    case 'DELETE_PROTOCOL':
      return {
        ...state,
        protocol: state.protocol.filter((item) => item.id !== action.payload),
      }
    case 'UPDATE_PROTOCOL':
      return {
        ...state,
        protocol: state.protocol.map((item) =>
          item.id === action.payload.id ? { ...item, ...action.payload.updatedData } : item
        ),
      }
    case 'EDIT_PROTOCOL':
      return {
        ...state,
        protocolEdit: {
          item: action.payload,
          edit: true,
        },
      }
    case 'EDIT_WIZARD_PROTOCOL':
      return {
        ...state,
        wizardProtocolEdit: {
          item: action.payload,
          edit: true,
        },
      }
    default:
      return state
  }
}

export default protocolReducer