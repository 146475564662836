import { Link, NavLink, useLocation } from "react-router-dom";
import "../../../assets/css/Sidebar.css";
import logoMedic from "../../../assets/images/logo/logo.png";
import { useState, useEffect } from "react";

/* icons */
import { SiAwsorganizations, SiOpslevel } from "react-icons/si";
import { MdOutlineDashboard } from "react-icons/md";
import { TbSettingsShare } from "react-icons/tb";
import { LuBoxes } from "react-icons/lu";
import {
  MdOutlineClose,
  MdCategory,
  MdOutlineImageAspectRatio,
} from "react-icons/md";
import { FaRegCircle } from "react-icons/fa";
import { GiMedicines } from "react-icons/gi";
import { BsChevronRight } from "react-icons/bs";
import { BsChevronUp } from "react-icons/bs";

/* on resizing add classes in sidebar for toggle */
function Sidebar() {
  const [showSidebar, setShowSidebar] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isOpenList, setIsOpenList] = useState([true]);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const location = useLocation();

  /* handle resize */
  useEffect(() => {
    const handleResize = () => {
      setShowSidebar(window.innerWidth < 1200);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const currentUrl = window.location.href;
    setIsActive(
      currentUrl.includes(location.pathname.startsWith("/organization"))
    );
  }, [location.pathname]);

  const isAdminRoute = location.pathname.startsWith("/admin");
  const isOrganizationRoute = location.pathname.startsWith("/organization");

  const organizationRoutes = [
    { id: 1, name: "All Categories", path: "/organization/categories/index" },
    { id: 2, name: "Add Category", path: "/organization/categories/create" },
  ];

  const medicineRoutes = [
    { id: 1, name: "All Medicine", path: "/organization/medicine/index" },
    { id: 2, name: "Add Medicine", path: "/organization/medicine/create" },
  ];

  const protocolRoutes = [
    { id: 1, name: "All Protocols", path: "/organization/protocol/index" },
    { id: 2, name: "Add Protocol", path: "/organization/protocol/create" },
  ];

  const levelRoutes = [
    { id: 1, name: "All Levels", path: "/organization/level/index" },
    { id: 2, name: "Add Level", path: "/organization/level/create" },
  ];

  const AccountSetting = [
    { id: 1, name: "Profile", path: "/organization/setting/profile" },
    {
      id: 2,
      name: "Change Password",
      path: "/organization/setting/changepassword",
    },
    { id: 3, name: "Payment Plan", path: "/organization/setting/paymentplan" },
    { id: 4, name: "Create User", path: "/organization/setting/users" },
  ];

  const toggleIsOpen = (index) => {
    console.log("before toggle", isOpenList);
    setIsOpenList((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
    console.log("After toggle:", isOpenList);
  };
  const handleDropdownClick = (dropdownId) => {
    setActiveDropdown(activeDropdown === dropdownId ? null : dropdownId);
  };

  return (
    <div
      className={`sidebar ${showSidebar && "offcanvas offcanvas-start"}`}
      id="offcanvasExample"
      aria-labelledby="offcanvasExampleLabel"
    >
      <a
        className="position-absolute end-0 p-3 text-dark close-sidebar-toggle"
        role="button"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      >
        <MdOutlineClose size={25} />
      </a>
      <div className="sidebar-logo d-flex align-items-center justify-content-center">
        <Link to="/admin/dashboard" className="brand-logo">
          <div className="d-flex justify-content-center align-items-center">
            <img className="logo-medic " src={logoMedic} alt="MedicSnow Logo" />
            <span className="ps-3 fw-bold fs-5 ">MedicSnow</span>
          </div>
        </Link>
      </div>
      <div className="sidebar-menu pt-4">
        <ul className="sidebar-dropdown d-flex flex-column gap-1 ">
          {/* Admin Routes List */}
          {isAdminRoute && (
            <>
              <li>
                <NavLink
                  to="/admin/dashboard"
                  className="d-flex align-items-center fs-5 gap-2"
                >
                  <MdOutlineDashboard />
                  <span>Dashboard</span>
                </NavLink>
              </li>
              <li>
                <a
                  className={`d-flex align-items-center fs-5 gap-2 menuCollapse ${isActive && "active"
                    }`}
                  data-bs-toggle="collapse"
                  href="#collapseModule"
                  data-bs-target="#collapseModule"
                  aria-expanded="false"
                  aria-controls="collapseModule"
                >
                  <LuBoxes />
                  <span>Modules</span>
                </a>
                <ul className="submenu collapse" id="collapseModule">
                  <li>
                    <NavLink
                      to="/admin/modules"
                      className="d-flex align-items-center gap-2"
                      end
                    >
                      <FaRegCircle />
                      <span>List Modules</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/admin/modules/create"
                      className="d-flex align-items-center gap-2"
                      end
                    >
                      <FaRegCircle />
                      <span>Add Modules</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <a
                  className={`d-flex align-items-center fs-5 gap-2 menuCollapse ${isActive && "active"
                    }`}
                  data-bs-toggle="collapse"
                  href="#collapseOrganizations"
                  data-bs-target="#collapseOrganizations"
                  aria-expanded="false"
                  aria-controls="collapseOrganizations"
                >
                  <SiAwsorganizations />
                  <span>Organizations</span>
                </a>
                <ul className="submenu collapse" id="collapseOrganizations">
                  <li>
                    <NavLink to="/admin/organization">
                      List Organizations
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/admin/organization/create">
                      Add Organizations
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink
                  to="/admin-settings"
                  className="d-flex align-items-center fs-5 gap-2"
                >
                  <TbSettingsShare />
                  <span>Settings</span>
                </NavLink>
              </li>
            </>
          )}
          {/* Organization Routes List */}
          {isOrganizationRoute && (
            <>
              <li>
                <NavLink
                  to="/organization/dashboard"
                  className="d-flex align-items-center fs-5 gap-2"
                >
                  <MdOutlineDashboard />
                  <span>Dashboard</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/organization/wizard"
                  className="d-flex align-items-center fs-5 gap-2"
                >
                  <MdOutlineDashboard />
                  <span>Wizard</span>
                </NavLink>
              </li>
              <li
                onClick={() => {
                  toggleIsOpen(0);
                  handleDropdownClick("collapseCategory");
                }}
              >
                <a
                  className={`d-flex align-items-center fs-5 gap-2 menuCollapse ${isActive && "active"
                    }`}
                  data-bs-toggle="collapse"
                  href="#collapseCategory"
                  data-bs-target="#collapseCategory"
                  aria-expanded="false"
                  aria-controls="collapseCategory"
                >
                  <MdCategory />
                  <span>Categories</span>
                  {activeDropdown === "collapseCategory" ? (
                    <BsChevronUp className="position-absolute chevron" />
                  ) : (
                    <BsChevronRight className="position-absolute chevron" />
                  )}
                </a>
                <ul
                  className={`submenu collapse ${activeDropdown === "collapseCategory" ? "show" : ""
                    }`}
                  id="collapseCategory"
                >
                  {organizationRoutes.map((route) => (
                    <li key={route.id}>
                      <NavLink
                        to={route.path}
                        className="d-flex align-items-center gap-2"
                        end
                      >
                        <FaRegCircle />
                        <span>{route.name}</span>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </li>
              <li
                onClick={() => {
                  toggleIsOpen(1);
                  handleDropdownClick("collapseMedicine");
                }}
              >
                <a
                  className={`d-flex align-items-center fs-5 gap-2 menuCollapse ${isActive && "active"
                    }`}
                  data-bs-toggle="collapse"
                  href="#collapseMedicine"
                  data-bs-target="#collapseMedicine"
                  aria-expanded="false"
                  aria-controls="collapseMedicine"
                >
                  <GiMedicines />
                  <span>Medicine</span>
                  {activeDropdown === "collapseMedicine" ? (
                    <BsChevronUp className="position-absolute chevron" />
                  ) : (
                    <BsChevronRight className="position-absolute chevron" />
                  )}
                </a>
                <ul
                  className={`submenu collapse ${activeDropdown === "collapseMedicine" ? "show" : ""
                    }`}
                  id="collapseMedicine"
                >
                  {medicineRoutes.map((route) => (
                    <li key={route.id}>
                      <NavLink
                        to={route.path}
                        className="d-flex align-items-center gap-2"
                        end
                      >
                        <FaRegCircle />
                        <span>{route.name}</span>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </li>
              <li
                onClick={() => {
                  toggleIsOpen(2);
                  handleDropdownClick("collapseProtocols");
                }}
              >
                <a
                  className={`d-flex align-items-center fs-5 gap-2 menuCollapse ${isActive && "active"
                    }`}
                  data-bs-toggle="collapse"
                  href="#collapseProtocols"
                  data-bs-target="#collapseProtocols"
                  aria-expanded="false"
                  aria-controls="collapseProtocols"
                >
                  <MdOutlineImageAspectRatio />
                  <span>Protocols</span>
                  {activeDropdown === "collapseProtocols" ? (
                    <BsChevronUp className="position-absolute chevron" />
                  ) : (
                    <BsChevronRight className="position-absolute chevron" />
                  )}
                </a>
                <ul
                  className={`submenu collapse ${activeDropdown === "collapseProtocols" ? "show" : ""
                    }`}
                  id="collapseProtocols"
                >
                  {protocolRoutes.map((route) => (
                    <li key={route.id}>
                      <NavLink
                        to={route.path}
                        className="d-flex align-items-center gap-2"
                        end
                      >
                        <FaRegCircle />
                        <span>{route.name}</span>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </li>
              <li
                onClick={() => {
                  toggleIsOpen(3);
                  handleDropdownClick("collapseLevels");
                }}
              >
                <a
                  className={`d-flex align-items-center fs-5 gap-2 menuCollapse ${isActive && "active"
                    }`}
                  data-bs-toggle="collapse"
                  href="#collapseLevels"
                  data-bs-target="#collapseLevels"
                  aria-expanded="false"
                  aria-controls="collapseLevels"
                >
                  <SiOpslevel />
                  <span>Levels</span>
                  {activeDropdown === "collapseLevels" ? (
                    <BsChevronUp className="position-absolute chevron" />
                  ) : (
                    <BsChevronRight className="position-absolute chevron" />
                  )}
                </a>
                <ul
                  className={`submenu collapse ${activeDropdown === "collapseLevels" ? "show" : ""
                    }`}
                  id="collapseLevels"
                >
                  {levelRoutes.map((route) => (
                    <li key={route.id}>
                      <NavLink
                        to={route.path}
                        className="d-flex align-items-center gap-2"
                        end
                      >
                        <FaRegCircle />
                        <span>{route.name}</span>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                <NavLink
                  to="/organization/settings/roles"
                  className="d-flex align-items-center fs-5 gap-2"
                >
                  <MdOutlineDashboard />
                  <span>Roles</span>
                </NavLink>
              </li>
              <li>
                <a
                  className={`d-flex align-items-center fs-5 gap-2 menuCollapse ${isActive && "active"
                    }`}
                  data-bs-toggle="collapse"
                  href="#collapseAccount"
                  data-bs-target="#collapseAccount"
                  aria-expanded="false"
                  aria-controls="collapseAccount"
                >
                  <SiOpslevel />
                  <span>Account Setting</span>
                </a>
                <ul className="submenu collapse" id="collapseAccount">
                  {AccountSetting.map((route) => (
                    <li key={route.id}>
                      <NavLink
                        to={route.path}
                        className="d-flex align-items-center gap-2"
                        end
                      >
                        <FaRegCircle />
                        <span>{route.name}</span>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>

    /* AccountSetting */
  );
}
export default Sidebar;
