const userReducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        user: action.payload,
        loading: false,
      }
    case 'ADD_USER':
      return {
        ...state,
        user: [action.payload, ...state.user],
      }
    case 'DELETE_USER':
      return {
        ...state,
        user: state.user.filter((item) => item.id !== action.payload),
      }
    case 'UPDATE_USER':
      return {
        ...state,
        user: state.user.map((item) =>
          item.id === action.payload.id ? { ...item, ...action.payload.updatedData } : item
        ),
      }
    case 'EDIT_USER':
      return {
        ...state,
        userEdit: {
          item: action.payload,
          edit: true,
        },
      }
    default:
      return state
  }
}

export default userReducer