const levelReducer = (state, action) => {
  switch (action.type) {
    case 'SET_LEVEL':
      return {
        ...state,
        level: action.payload,
        loading: false,
      }
    case 'ADD_LEVEL':
      return {
        ...state,
        level: [action.payload, ...state.level],
      }
    case 'DELETE_LEVEL':
      return {
        ...state,
        level: state.level.filter((item) => item.id !== action.payload),
      }
    case 'UPDATE_LEVEL':
      return {
        ...state,
        level: state.level.map((item) =>
          item.id === action.payload.id ? { ...item, ...action.payload.updatedData } : item
        ),
      }
    case 'EDIT_LEVEL':
      return {
        ...state,
        levelEdit: {
          item: action.payload,
          edit: true,
        },
      }
    default:
      return state
  }
}

export default levelReducer