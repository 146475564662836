import React, { useState } from 'react';
const MedicineFields = ({ fields, onChange }) => {
  return (
    <>
      {fields.map((field, index) => (
        <div key={index}>
          <label>Medicine:</label>
          <input
            type="text"
            value={field.medicine}
            onChange={(e) => onChange(index, 'medicine', e.target.value)}
          />
          <label>Dosage:</label>
          <input
            type="text"
            value={field.dosage}
            onChange={(e) => onChange(index, 'dosage', e.target.value)}
          />
          <label>Time:</label>
          <input
            type="text"
            value={field.time}
            onChange={(e) => onChange(index, 'time', e.target.value)}
          />
        </div>
      ))}
    </>
  );
};

const OrgAdditionalModulesIndex = () => {
  const [childFields, setChildFields] = useState([{ medicine: '', dosage: '', time: '', type: 'child' }]);
  const [adultFields, setAdultFields] = useState([{ medicine: '', dosage: '', time: '', type: 'adult' }]);

  const handleAddMedicine = (type) => {
    if (type === 'child') {
      setChildFields([...childFields, { medicine: '', dosage: '', time: '' }]);
    } else if (type === 'adult') {
      setAdultFields([...adultFields, { medicine: '', dosage: '', time: '' }]);
    }
  };

  const handleMedicineChange = (type, index, field, value) => {
    const fields = type === 'child' ? [...childFields] : [...adultFields];
    fields[index][field] = value;
    type === 'child' ? setChildFields(fields) : setAdultFields(fields);
  };

  const handleSubmit = () => {
    // Collect and handle the data as needed
    console.log('Child Fields:', childFields);
    console.log('Adult Fields:', adultFields);
  };

  return (
    <div>
      <h2>Child Section</h2>
      <label>Category:</label>
      <input type="text" placeholder="Category" />
      <label>Age:</label>
      <input type="number" placeholder="Age" />
      <MedicineFields fields={childFields} onChange={(index, field, value) => handleMedicineChange('child', index, field, value)} />
      <button onClick={() => handleAddMedicine('child')}>Add Medicine</button>

      <h2>Adult Section</h2>
      <label>Category:</label>
      <input type="text" placeholder="Category" />
      <label>Age:</label>
      <input type="number" placeholder="Age" />
      <MedicineFields fields={adultFields} onChange={(index, field, value) => handleMedicineChange('adult', index, field, value)} />
      <button onClick={() => handleAddMedicine('adult')}>Add Medicine</button>

      <button onClick={handleSubmit}>Submit</button>
    </div>
  );
};

export default OrgAdditionalModulesIndex;
