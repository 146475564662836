import { Link } from 'react-router-dom'
import { useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import myData from '../data'
import "primereact/resources/themes/lara-light-cyan/theme.css"
import "../../../../assets/css/Datatable.css"
import "../../../../assets/css/AdminPages.css"

/* RBT Components */
import { Button } from 'react-bootstrap'
import Offcanvas from 'react-bootstrap/Offcanvas'
import Form from 'react-bootstrap/Form'

/* icons */
import { TiEdit } from "react-icons/ti"
import { MdOutlineDeleteSweep } from "react-icons/md"
import { FaPlus } from "react-icons/fa6"
import { FaRegCircle } from "react-icons/fa"

const ModulesIndex = ({ ...props }) => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [module, setModule] = useState(myData)

  const verifiedBodyTemplate = (rowData) => {
    return (
      <div className="d-flex align-items-center gap-2">
        <Link to={`/admin/Module/edit/${rowData.id}`} className='cta-icons'>
          <TiEdit size={30} color='darkGrey' />
        </Link>
        <a href="#" className='cta-icons'>
          <MdOutlineDeleteSweep size={30} color='darkGrey' />
        </a>
      </div>
    )
  }
  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h1>MedicSnow Module</h1>
      </div>
      <div className="d-flex align-items-center justify-content-end bg-white shadow rounded-top p-3">
        <Button variant="primary" onClick={handleShow} className='d-flex align-items-center gap-2'>
          <FaPlus />
          Add New Module
        </Button>
      </div>
      <Offcanvas show={show} onHide={handleClose} placement="end" {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Module</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form>
            <Form.Group className="mb-3" controlId="ModuleName">
              <Form.Label>Module Name</Form.Label>
              <Form.Control type="email" placeholder="Enter Module Name" />
            </Form.Group>
            <Button variant="primary" type="submit">
              Add Module
            </Button>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
      <div className="row">
        <div className="col-md-12">
          <div className="card bg-white shadow border-0">
            <div className="card-body p-0">
              <DataTable value={module} paginator rows={2} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}>
                <Column field="id" header="ID"></Column>
                <Column field="name" header="Name"></Column>
                <Column field="action" header="Action" dataType="boolean" style={{ minWidth: '6rem' }} body={verifiedBodyTemplate} />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ModulesIndex