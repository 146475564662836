import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import MedicationContext from '../../orgContext/medication/MedicationContext'
import ProtocolContext from '../../orgContext/protocol/ProtocolContext'
import { Card, Button, Form } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import Row from 'react-bootstrap/Row'
import Tab from 'react-bootstrap/Tab'
import Modal from 'react-bootstrap/Modal'

const OrgMedicineEdit = () => {
  const { medications, medicationEdit, getMedication, updateMedication, } = useContext(MedicationContext)
  const params = useParams()
  useEffect(() => {
    getMedication(params.id)
  }, [])

  const [editMedicationData, setEditMedicationData] = useState({
    name: '',
    description: '',
    protocols: [],
  })
  useEffect(() => {
    setEditMedicationData({
      name: medicationEdit.name || '',
      description: medicationEdit.description || '',
      protocols: medicationEdit.protocols || [],
    })

    if (medicationEdit.protocols && medicationEdit.protocols.length > 0) {
      const initialTabs = medicationEdit.protocols.map((protocol, index) => {
        const newKey = `tab${index + 1} `
        return {
          key: newKey,
          title: `${protocol.protocol_name} `,
          content: {
            adult_dose: protocol.doses[0].adult || '',
            pediatric_dose: protocol.doses[0].kid || '',
            repeat_interval: protocol.repeat_interval || '',
            maximum_dose: protocol.maximum_dose || '',
            instruction: protocol.instructions || '',
            routes: protocol.routes || '',
          },
          protocolId: protocol.protocol_id,
        }
      })

      setTabs(initialTabs)
      setActiveKey(initialTabs[0]?.key || null)
    }
  }, [medicationEdit])
  // console.log(editMedicationData)
  const { protocol, loading: protocolLoading } = useContext(ProtocolContext)
  const [tabs, setTabs] = useState([])
  const [activeKey, setActiveKey] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [protocolNames, setProtocolNames] = useState([])
  const availableProtocols = protocolNames.filter(
    (protocolName) => !tabs.some((tab) => tab.title === `${protocolName}`)
  )
  console.log(tabs)
  // console.log(protocolNames)
  useEffect(() => {
    if (!protocolLoading) {
      setProtocolNames(protocol.map(item => item.name))
    }
  }, [protocol, protocolLoading])

  const addTab = (selectedProtocolName, selectedProtocolId) => {
    console.log('added protocol:', selectedProtocolName, 'ID:', selectedProtocolId)
    if (
      selectedProtocolId &&
      !tabs.some((tab) => tab.title === `${selectedProtocolName} `)
    ) {
      const newKey = `tab${tabs.length + 1} `
      const newTab = {
        key: newKey,
        title: `${selectedProtocolName}`,
        content: {
          adult_dose: '',
          pediatric_dose: '',
          repeat_interval: '',
          maximum_dose: '',
          instruction: '',
          routes: '',
        },
        protocolId: selectedProtocolId,
      }
      setTabs([...tabs, newTab])
      setActiveKey(newKey)
    }

    setShowModal(false)
  }

  const removeTab = (tabKey) => {
    setTabs((prevTabs) => {
      const removedTabIndex = prevTabs.findIndex((tab) => tab.key === tabKey)
      const newTabs = prevTabs.filter((tab) => tab.key !== tabKey)
      let newActiveKey = null

      if (newTabs.length > 0) {
        const newIndex = removedTabIndex >= newTabs.length ? newTabs.length - 1 : removedTabIndex
        newActiveKey = newTabs[newIndex].key
      }

      setActiveKey(newActiveKey)
      return newTabs
    })
  }

  const handleShowModal = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    // setTabs([])
    setShowModal(false)
  }

  const handleInputChange = (tabKey, field, value) => {
    setTabs((prevTabs) =>
      prevTabs.map((tab) =>
        tab.key === tabKey ? { ...tab, content: { ...tab.content, [field]: value } } : tab
      )
    )
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const name = e.target.elements.name.value
    const description = e.target.elements.description.value

    // console.log(tabs)
    const protocols = tabs.map((tab) => ({
      protocol_name: tab.title,
      protocol_id: tab.protocolId,
      doses: [
        {
          adult: tab.content.adult_dose,
          pediatric: tab.content.pediatric_dose,
        },
      ],
      repeat_interval: tab.content.repeat_interval,
      maximum_dose: tab.content.maximum_dose,
      routes: tab.content.routes,
      instructions: tab.content.instruction,
    }))

    const newMedication = {
      name: name,
      description: description,
      protocols: protocols,
    }

    console.log(newMedication)

    updateMedication(medicationEdit.id, newMedication)
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h1>Add Medicine</h1>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Card border='0' bg='body-secondary' className="shadow">
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>Medicine Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Medicine Name"
                    value={editMedicationData.name}
                    onChange={(e) => setEditMedicationData({ ...editMedicationData, name: e.target.value })}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="description">
                  <Form.Label>Medicine Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Description"
                    value={editMedicationData.description}
                    onChange={(e) => setEditMedicationData({ ...editMedicationData, description: e.target.value })}
                  />
                </Form.Group>
                <Tab.Container id="left-tabs-example" activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
                  <Row>
                    <Col sm={3}>
                      <Nav variant="pills" className="flex-column">
                        {tabs.map((tab) => (
                          <Nav.Item key={tab.key}>
                            <Nav.Link eventKey={tab.key}>
                              {tab.title}
                              <Button variant="danger" size="sm" onClick={() => removeTab(tab.key)} className="ml-2">
                                Remove
                              </Button>
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                      <Button variant="primary" onClick={handleShowModal}>
                        Add Tab
                      </Button>
                    </Col>
                    <Col sm={9}>
                      <Tab.Content>
                        {tabs.map((tab) => (
                          <Tab.Pane key={tab.key} eventKey={tab.key}>
                            <Row className="mb-3">
                              <Form.Group as={Col} controlId={`adult_dose_${tab.key} `}>
                                <Form.Label>Adult Dose</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Adult Dose"
                                  value={tab.content.adult_dose}
                                  onChange={(e) => handleInputChange(tab.key, 'adult_dose', e.target.value)}
                                />
                              </Form.Group>

                              <Form.Group as={Col} controlId={`pediatric_dose_${tab.key} `}>
                                <Form.Label>Kid Dose</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Kid Dose"
                                  value={tab.content.pediatric_dose}
                                  onChange={(e) => handleInputChange(tab.key, 'pediatric_dose', e.target.value)}
                                />
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group as={Col} controlId={`repeat_interval_${tab.key} `}>
                                <Form.Label>Repeat Interval</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Repeat Interval"
                                  value={tab.content.repeat_interval}
                                  onChange={(e) => handleInputChange(tab.key, 'repeat_interval', e.target.value)}
                                />
                              </Form.Group>
                              <Form.Group as={Col} controlId={`maximum_dose_${tab.key} `}>
                                <Form.Label>Maximum Dose</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Maximum Dose"
                                  value={tab.content.maximum_dose}
                                  onChange={(e) => handleInputChange(tab.key, 'maximum_dose', e.target.value)}
                                />
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group controlId={`instruction_${tab.key} `}>
                                <Form.Label>Instruction</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter instruction"
                                  value={tab.content.instruction}
                                  onChange={(e) => handleInputChange(tab.key, 'instruction', e.target.value)}
                                />
                              </Form.Group>
                              <Form.Group as={Col} controlId={`routes_${tab.key} `}>
                                <Form.Label>Routes</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Routes"
                                  value={tab.content.routes}
                                  onChange={(e) => handleInputChange(tab.key, 'routes', e.target.value)}
                                />
                              </Form.Group>
                            </Row>
                          </Tab.Pane>
                        ))}
                      </Tab.Content>
                    </Col>
                  </Row>

                  {/* Protocol Selection Modal */}
                  <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>Select Protocol</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {protocolNames
                        .filter(
                          (protocolName) =>
                            !tabs.some((tab) => tab.title === `${protocolName}`)
                        )
                        .map((filteredProtocol) => (
                          <Button
                            key={filteredProtocol}
                            variant="primary"
                            onClick={() =>
                              addTab(filteredProtocol, protocol.find(p => p.name === filteredProtocol)?.protocol_id)
                            }
                          >
                            {filteredProtocol}
                          </Button>
                        ))}
                    </Modal.Body>
                  </Modal>
                </Tab.Container>
                <Button variant="primary" type="submit">
                  Update Medicine
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  )
}
export default OrgMedicineEdit