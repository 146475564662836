import { createContext, useEffect, useReducer } from 'react'
import levelReducer from './LevelReducer'
import PropTypes from 'prop-types'


const LevelContext = createContext()

const MEDIC_URL = process.env.REACT_APP_MEDIC_URL
const MEDIC_TOKEN = process.env.REACT_APP_MEDIC_TOKEN

export const LevelProvider = ({ children }) => {

  const initialState = {
    loading: true,
    level: [],
    levelEdit: {
      item: {},
      edit: false,
    },
  }
  const [state, dispatch] = useReducer(levelReducer, initialState)


  useEffect(() => {
    fetchLevel()
  }, [])

  /* Fetch Categories */
  const fetchLevel = async () => {
    const response = await fetch(`${MEDIC_URL}/levels`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${MEDIC_TOKEN}`,
      }
    })
    const data = await response.json()
    dispatch({ type: 'SET_LEVEL', payload: data })
  }

  /* Add Level */

  const addLevel = async (newLevel) => {
    const response = await fetch(`${MEDIC_URL}/levels`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${MEDIC_TOKEN}`
      },
      body: JSON.stringify(newLevel),
    })

    if (response.ok) {
      const data = await response.json()
      dispatch({ type: 'ADD_LEVEL', payload: data })
      fetchLevel()
    } else {
      console.error(`Error Adding Level: ${response.statusText}`)
    }
  }

  /* Delete Level */
  const deleteLevel = async (id) => {
    if (window.confirm(`Are you sure you want to delete?`)) {
      await fetch(`${MEDIC_URL}/levels/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${MEDIC_TOKEN}`,
        }
      })
      dispatch({ type: 'DELETE_LEVEL', payload: id })
      fetchLevel()
    }
  }


  /* Update Level */
  const updateLevel = async (id, updItem) => {
    const response = await fetch(`${MEDIC_URL}/levels/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${MEDIC_TOKEN}`,
      },
      body: JSON.stringify(updItem),
    })

    if (response.ok) {
      const updatedData = await response.json()
      dispatch({ type: 'UPDATE_LEVEL', payload: { id, updatedData } })
      fetchLevel()
    } else {
      console.error(`Error updating level: ${response.statusText}`)
    }
  }

  /* Edit Level */
  const editLevel = (item) => {
    dispatch({ type: 'EDIT_LEVEL', payload: item })
  }

  return (
    <LevelContext.Provider
      value={{
        level: state.level,
        loading: state.loading,
        levelEdit: state.levelEdit,
        addLevel,
        editLevel,
        updateLevel,
        deleteLevel
      }}>
      {children}
    </LevelContext.Provider>
  )
}

LevelProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LevelContext