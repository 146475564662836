import { createContext, useEffect, useReducer } from 'react'
import categoryReducer from './CategoryReducer'
import PropTypes from 'prop-types'


const CategoryContext = createContext()

const MEDIC_URL = process.env.REACT_APP_MEDIC_URL
const MEDIC_TOKEN = process.env.REACT_APP_MEDIC_TOKEN

export const CategoryProvider = ({ children }) => {

  const initialState = {
    loading: true,
    category: [],
    categoryEdit: {
      item: {},
      edit: false,
    },
  }
  const [state, dispatch] = useReducer(categoryReducer, initialState)


  useEffect(() => {
    fetchCategory()
  }, [])

  /* Fetch Categories */
  const fetchCategory = async () => {
    const response = await fetch(`${MEDIC_URL}/categories`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${MEDIC_TOKEN}`,
      }
    })
    const data = await response.json()
    dispatch({ type: 'SET_CATEGORY', payload: data })
  }

  /* Add Category */

  const addCategory = async (newCategory) => {
    const response = await fetch(`${MEDIC_URL}/categories`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${MEDIC_TOKEN}`
      },
      body: JSON.stringify(newCategory),
    })

    if (response.ok) {
      const data = await response.json()
      dispatch({ type: 'ADD_CATEGORY', payload: data })
      fetchCategory()
    } else {
      console.error(`Error Adding category: ${response.statusText}`)
    }
  }

  /* Delete Category */
  const deleteCategory = async (id) => {
    if (window.confirm(`Are you sure you want to delete?`)) {
      await fetch(`${MEDIC_URL}/categories/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${MEDIC_TOKEN}`,
        }
      })
      dispatch({ type: 'DELETE_CATEGORY', payload: id })
      fetchCategory()
    }
  }


  /* Update Category */
  const updateCategory = async (id, updItem) => {
    const response = await fetch(`${MEDIC_URL}/categories/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${MEDIC_TOKEN}`,
      },
      body: JSON.stringify(updItem),
    })

    if (response.ok) {
      const updatedData = await response.json()
      dispatch({ type: 'UPDATE_CATEGORY', payload: { id, updatedData } })
      fetchCategory()
    } else {
      console.error(`Error updating category: ${response.statusText}`)
    }
  }

  /* Edit Category */
  const editCategory = (item) => {
    dispatch({ type: 'EDIT_CATEGORY', payload: item })
  }

  return (
    <CategoryContext.Provider
      value={{
        category: state.category,
        loading: state.loading,
        categoryEdit: state.categoryEdit,
        addCategory,
        editCategory,
        updateCategory,
        deleteCategory
      }}>
      {children}
    </CategoryContext.Provider>
  )
}

CategoryProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CategoryContext