const categoryReducer = (state, action) => {
  switch (action.type) {
    case 'SET_CATEGORY':
      return {
        ...state,
        category: action.payload,
        loading: false,
      }
    case 'ADD_CATEGORY':
      return {
        ...state,
        category: [action.payload, ...state.category],
      }
    case 'DELETE_CATEGORY':
      return {
        ...state,
        category: state.category.filter((item) => item.id !== action.payload),
      }
    case 'UPDATE_CATEGORY':
      return {
        ...state,
        category: state.category.map((item) =>
          item.id === action.payload.id ? { ...item, ...action.payload.updatedData } : item
        ),
      }
    case 'EDIT_CATEGORY':
      return {
        ...state,
        categoryEdit: {
          item: action.payload,
          edit: true,
        },
      }
    default:
      return state
  }
}

export default categoryReducer