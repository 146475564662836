import { HiOutlineLogout } from "react-icons/hi";
import { CgMenuRightAlt } from "react-icons/cg";
import "../../../assets/css/Header.css";
/* images */
import adminAvatar from "../../../assets/images/avatar/admin-avatar.jpeg";

/* icons */
import { TbUserCheck } from "react-icons/tb";
import { CiSettings } from "react-icons/ci";

function Header() {
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("permissions");
    // window.location.href = "http://localhost:3000/organization/login";
    window.location.href = "https://react.wevozec.com/organization/login";
    console.log("logout");
  };
  return (
    <div className="header d-flex">
      <div className="d-flex align-items-center justify-content-between w-100 px-xl-5 px-3">
        <nav className="navbar navbar-expand w-100">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <a
                className="text-dark sidebar-toggle"
                data-bs-toggle="offcanvas"
                href="#offcanvasExample"
                role="button"
                aria-controls="offcanvasExample"
              >
                <CgMenuRightAlt size={30} />
              </a>
            </div>
            <ul className="navbar-nav header-right">
              <div className="nav-item dropdown header-profile ms-sm-4 ms-2 dropdown">
                <a
                  className="nav-link dropdown-toggle d-flex align-items-center"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div className="header-info">
                    <span className="c-outer-space fs-6">
                      <strong>Ziad Saleemi.</strong>
                    </span>
                    <p className="fs-12 mb-0">Admin</p>
                  </div>
                  <img src={adminAvatar} alt="Avatar" />
                </a>
                <ul className="mt-2 dropdown-menu bg-white border-0 shadow fs-6">
                  <li>
                    <a
                      className="dropdown-item d-flex align-items-center gap-2"
                      href="#"
                    >
                      <TbUserCheck size={23} />
                      Profile
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item d-flex align-items-center gap-2"
                      href="#"
                    >
                      <CiSettings size={23} />
                      Settings
                    </a>
                  </li>
                  <li>
                    <div className="dropdown-divider"></div>
                  </li>
                  <li>
                    <a
                      className="dropdown-item d-flex align-items-center gap-2"
                      href="#"
                      onClick={logout}
                    >
                      <HiOutlineLogout size={23} />
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
}
export default Header;
