import { createContext, useEffect, useReducer } from 'react'
import userReducer from './UserReducer'
import PropTypes from 'prop-types'


const UserContext = createContext()

const MEDIC_URL = process.env.REACT_APP_MEDIC_URL
const MEDIC_TOKEN = process.env.REACT_APP_MEDIC_TOKEN

export const UserProvider = ({ children }) => {

  const initialState = {
    loading: true,
    user: [],
    userEdit: {
      item: {},
      edit: false,
    },
  }
  const [state, dispatch] = useReducer(userReducer, initialState)


  useEffect(() => {
    fetchUsers()
  }, [])

  /* Fetch Categories */
  const fetchUsers = async () => {
    const response = await fetch(`http://localhost:4000/organizationUsers`, {
      method: 'GET',
      headers: {
        // Authorization: `Bearer ${MEDIC_TOKEN}`,
      }
    })
    const data = await response.json()
    dispatch({ type: 'SET_USER', payload: data })
  }

  /* Add user */

  const addUser = async (newUser) => {
    const response = await fetch(`http://localhost:4000/organizationUsers`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${MEDIC_TOKEN}`
      },
      body: JSON.stringify(newUser),
    })

    if (response.ok) {
      const data = await response.json()
      dispatch({ type: 'ADD_USER', payload: data })
      fetchUsers()
    } else {
      console.error(`Error Adding User: ${response.statusText}`)
    }
  }

  /* Delete User */
  const deleteUser = async (id) => {
    if (window.confirm(`Are you sure you want to delete?`)) {
      await fetch(`http://localhost:4000/organizationUsers/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `Bearer ${MEDIC_TOKEN}`,
        }
      })
      dispatch({ type: 'DELETE_USER', payload: id })
      fetchUsers()
    }
  }


  /* Update User */
  const updateUser = async (id, updItem) => {
    console.log(id)
    const response = await fetch(`http://localhost:4000/organizationUsers/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${MEDIC_TOKEN}`,
      },
      body: JSON.stringify(updItem),
    })

    if (response.ok) {
      const updatedData = await response.json()
      dispatch({ type: 'UPDATE_USER', payload: { id, updatedData } })
      fetchUsers()
    } else {
      console.error(`Error updating User: ${response.statusText}`)
    }
  }

  /* Edit User */
  const editUser = (item) => {
    dispatch({ type: 'EDIT_USER', payload: item })
  }

  return (
    <UserContext.Provider
      value={{
        user: state.user,
        loading: state.loading,
        userEdit: state.userEdit,
        addUser,
        editUser,
        updateUser,
        deleteUser
      }}>
      {children}
    </UserContext.Provider>
  )
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default UserContext