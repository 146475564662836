import { createContext, useEffect, useReducer } from 'react'
import medicationReducer from './MedicationReducer'
import PropTypes from 'prop-types'


const MedicationContext = createContext()

const MEDIC_URL = process.env.REACT_APP_MEDIC_URL
const MEDIC_TOKEN = process.env.REACT_APP_MEDIC_TOKEN

export const MedicationProvider = ({ children }) => {

  const initialState = {
    loading: true,
    medication: [],
    medicationEdit: {
      item: {},
      edit: false,
    },
  }
  const [state, dispatch] = useReducer(medicationReducer, initialState)

  useEffect(() => {
    fetchMedications()
  }, [])

  /* Fetch Medications */
  const fetchMedications = async () => {
    const response = await fetch(`${MEDIC_URL}/medications`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${MEDIC_TOKEN}`,
      }
    })
    const data = await response.json()
    dispatch({ type: 'SET_MEDICATION', payload: data })
  }

  /* Get Medications */
  const getMedication = async (id) => {
    const response = await fetch(`${MEDIC_URL}/medications/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${MEDIC_TOKEN}`,
      }
    })
    const data = await response.json()
    dispatch({ type: 'GET_MEDICATION', payload: data })
  }

  /* Add Medication */
  const addMedication = async (newMedication) => {
    const response = await fetch(`${MEDIC_URL}/medications`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${MEDIC_TOKEN}`
      },
      body: JSON.stringify(newMedication),
    })

    const data = await response.json()
    newMedication.protocols.forEach(async (protocols) => {
      // console.log(protocols.protocol_id);
      // Fetch the protocol details
      const responseProtocol = await fetch(`${MEDIC_URL}/protocols/${protocols.protocol_id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${MEDIC_TOKEN}`,
        }
      })
      const dataProtocol = await responseProtocol.json()
      dataProtocol.medicines = dataProtocol.medicines || []
      // Update the protocol's medicines array
      dataProtocol.medicines.push(data.id)

      await fetch(`${MEDIC_URL}/protocols/${protocols.protocol_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${MEDIC_TOKEN}`,
        },
        body: JSON.stringify(dataProtocol),
      })
    })
    dispatch({ type: 'ADD_MEDICATION', payload: data })
  }

  /* Delete Medication */
  const deleteMedication = async (id) => {
    if (window.confirm(`Are you sure you want to delete?`)) {
      await fetch(`${MEDIC_URL}/medications/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${MEDIC_TOKEN}`,
        },
      })
      dispatch({ type: 'DELETE_MEDICATION', payload: id })
    }
  }


  /* Update Medication */
  const updateMedication = async (id, updItem) => {
    const response = await fetch(`${MEDIC_URL}/medications/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${MEDIC_TOKEN}`,
      },
      body: JSON.stringify(updItem),
    })

    if (response.ok) {
      const updatedData = await response.json()
      // console.log(updatedData)
      dispatch({ type: 'UPDATE_MEDICATION', payload: { id, updatedData } })
    } else {
      console.error(`Error updating medication: ${response.statusText}`)
    }
  }

  /* Edit Medication */
  const editMedication = (item) => {
    dispatch({ type: 'EDIT_MEDICATION', payload: item })
  }

  return (
    <MedicationContext.Provider
      value={{
        medications: state.medications,
        loading: state.loading,
        medicationEdit: state.medication,
        addMedication,
        editMedication,
        getMedication,
        updateMedication,
        deleteMedication
      }}>
      {children}
    </MedicationContext.Provider>
  )
}

MedicationProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MedicationContext