import Table from "react-bootstrap/Table";

import { FaRegEdit } from "react-icons/fa";
import { MdOutlineDeleteForever } from "react-icons/md";

const dummyData = [
  {
    user: "User 1",
    roles: "Admin",
    permission: "Yes",
  },
  {
    user: "User 2",
    roles: "Editor",
    permission: "Yes",
  },
  {
    user: "User 3",
    roles: "Subscriber",
    permission: "Yes",
  },
  {
    user: "User 4",
    roles: "Viewer",
    permission: "Yes",
  },
  {
    user: "User 5",
    roles: "Admin",
    permission: "Yes",
  },
  {
    user: "User 6",
    roles: "Editor",
    permission: "Yes",
  },
];

const RolesTable = () => {
  return (
    <>
      <Table className="container table mt-5">
        <thead>
          <tr>
            <th>USER</th>
            <th>Roles</th>
            <th>permission</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {dummyData.map((data, index) => (
            <tr key={index}>
              <td>
                <span className="text-nowrap">{data.user}</span>
              </td>
              <td>{data.roles}</td>
              <td>{data.permission}</td>

              <td>
                <span className="text-nowrap">
                  <button className="btn btn-sm btn-icon me-2">
                    <FaRegEdit />
                  </button>
                  <button className="btn btn-sm btn-icon ">
                    <MdOutlineDeleteForever size={20} />
                  </button>
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default RolesTable;
