import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Card from "react-bootstrap/Card";

import { LuCopy } from "react-icons/lu";
import { CiCircleInfo } from "react-icons/ci";
import RolesTable from '../roles/RolesTable'

import "../../../../../assets/css/RolesIndex.css";
import avatar1 from "../../../../../assets/images/Roles/Avatar_1.png";
import avatar2 from "../../../../../assets/images/Roles/Avatar_2.png";
import avatar3 from "../../../../../assets/images/Roles/Avatar_3.png";
import avatar4 from "../../../../../assets/images/Roles/Avatar_4.png";
import avatar5 from "../../../../../assets/images/Roles/Avatar_5.png";
import avatar6 from "../../../../../assets/images/Roles/Avatar_6.png";
import avatar7 from "../../../../../assets/images/Roles/Avatar_7.png";
import avatar8 from "../../../../../assets/images/Roles/Avatar_8.png";
import avatar9 from "../../../../../assets/images/Roles/Avatar_9.png";
import avatar10 from "../../../../../assets/images/Roles/Avatar_10.png";
import avatar11 from "../../../../../assets/images/Roles/Avatar_11.png";
import User from "../../../../../assets/images/Roles/user.png";

const RoleIndex = () => {
  const [showModal, setShowModal] = useState(false);

  const [selectAll, setSelectAll] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState({
    userManagement: {
      read: false,
      write: false,
      create: false,
    },
    contentManagement: {
      read: false,
      write: false,
      create: false,
    },
    disputesManagement: {
      read: false,
      write: false,
      create: false,
    },
    databaseManagement: {
      read: false,
      write: false,
      create: false,
    },
    financialManagement: {
      read: false,
      write: false,
      create: false,
    },
    reporting: {
      read: false,
      write: false,
      create: false,
    },
    apiControl: {
      read: false,
      write: false,
      create: false,
    },
    repositoryManagement: {
      read: false,
      write: false,
      create: false,
    },
    payroll: {
      read: false,
      write: false,
      create: false,
    },
  });

  const handleButtonClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSelectAll = () => {
    const newCheckboxStates = Object.keys(checkboxStates).reduce(
      (acc, category) => {
        acc[category] = {
          read: !selectAll,
          write: !selectAll,
          create: !selectAll,
        };
        return acc;
      },
      {}
    );
    setCheckboxStates(newCheckboxStates);
    setSelectAll(!selectAll);
  };

  const handleCheckboxChange = (category, permission) => {
    // sendToMockAPI(searchText, category, permission); // Include permission as well
    setCheckboxStates((prevStates) => ({
      ...prevStates,
      [category]: {
        ...prevStates[category],
        [permission]: !prevStates[category][permission],
      },
    }));
  };

  const [searchText, setSearchText] = useState("");
  const [category, setCategory] = useState("");

  const handleSearchInputChange = (event) => {
    const text = event.target.value;
    setSearchText(text);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    sendToMockAPI(searchText, category, "");
  };

  const sendToMockAPI = (text, category, permission) => {
    console.log("Sending data to mock API:", text, category, permission);

    const data = {
      searchText: text,
      category: category,
      permission: permission,
    };

    fetch("http://localhost:3001/data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
      })
      .catch((error) => {
        console.error("Error sending data to mock API:", error);
      });
  };
  return (
    <>
      <div className="main mx-2 p-2 mt-4 position-relative left-21 top-5">
        <div className="role-heading">
          <h3 className="heading-color">Roles List</h3>
        </div>
        <div className="d-flex align-items-center flex-wrap gap-3">
          <Card className="w-32 border-0 box-shadow">
            <Card.Body>
              <div className="d-flex justify-content-between">
                <h6 className="fw-normal mb-2">Total 4 users</h6>
                <ul className="list-unstyled d-flex align-items-center avatar-images mb-0">
                  <li>
                    <img
                      className="rounded-circle left-225"
                      src={avatar1}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="rounded-circle left-180"
                      src={avatar2}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="rounded-circle left-135"
                      src={avatar3}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="rounded-circle left-90"
                      src={avatar4}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="rounded-circle left-45"
                      src={avatar5}
                      alt=""
                    />
                  </li>
                </ul>
              </div>

              <div className="d-flex justify-content-between align-items-end mt-1">
                <div className="role-heading">
                  <h4 className="mb-1 heading-color">Administrator</h4>
                  <a href="#">
                    <span>Edit Role</span>
                  </a>
                </div>
                <a href="#">
                  <LuCopy className="fs-4 heading-color" />
                </a>
              </div>
            </Card.Body>
          </Card>
          <Card className="w-32 border-0 box-shadow">
            <Card.Body>
              <div className="d-flex justify-content-between">
                <h6 className="fw-normal mb-2">Total 7 users</h6>
                <ul className="list-unstyled d-flex align-items-center avatar-images mb-0">
                  <li>
                    <img
                      className="rounded-circle left-225"
                      src={avatar6}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="rounded-circle left-180"
                      src={avatar7}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="rounded-circle left-135"
                      src={avatar8}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="rounded-circle left-90"
                      src={avatar9}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="rounded-circle left-45"
                      src={avatar10}
                      alt=""
                    />
                  </li>
                </ul>
              </div>

              <div className="d-flex justify-content-between align-items-end mt-1">
                <div className="role-heading">
                  <h4 className="mb-1 heading-color">Administrator</h4>
                  <a href="#">
                    <span>Edit Role</span>
                  </a>
                </div>
                <a href="#">
                  <LuCopy className="fs-4 heading-color" />
                </a>
              </div>
            </Card.Body>
          </Card>
          <Card className="w-32 border-0 box-shadow">
            <Card.Body>
              <div className="d-flex justify-content-between">
                <h6 className="fw-normal mb-2">Total 5 users</h6>
                <ul className="list-unstyled d-flex align-items-center avatar-images mb-0">
                  <li>
                    <img
                      className="rounded-circle left-225"
                      src={avatar11}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="rounded-circle left-180"
                      src={avatar1}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="rounded-circle left-135"
                      src={avatar4}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="rounded-circle left-90"
                      src={avatar6}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="rounded-circle left-45"
                      src={avatar9}
                      alt=""
                    />
                  </li>
                </ul>
              </div>

              <div className="d-flex justify-content-between align-items-end mt-1">
                <div className="role-heading">
                  <h4 className="mb-1 heading-color">Administrator</h4>
                  <a href="#">
                    <span>Edit Role</span>
                  </a>
                </div>
                <a href="#">
                  <LuCopy className="fs-4 heading-color" />
                </a>
              </div>
            </Card.Body>
          </Card>
          <Card className="w-32 border-0 box-shadow">
            <Card.Body>
              <div className="d-flex justify-content-between">
                <h6 className="fw-normal mb-2">Total 3 users</h6>
                <ul className="list-unstyled d-flex align-items-center avatar-images mb-0">
                  <li>
                    <img
                      className="rounded-circle left-225"
                      src={avatar3}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="rounded-circle left-180"
                      src={avatar5}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="rounded-circle left-135"
                      src={avatar6}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="rounded-circle left-90"
                      src={avatar9}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="rounded-circle left-45"
                      src={avatar2}
                      alt=""
                    />
                  </li>
                </ul>
              </div>

              <div className="d-flex justify-content-between align-items-end mt-1">
                <div className="role-heading">
                  <h4 className="mb-1 heading-color">Support</h4>
                  <a href="#">
                    <span>Edit Role</span>
                  </a>
                </div>
                <a href="#">
                  <LuCopy className="fs-4 heading-color" />
                </a>
              </div>
            </Card.Body>
          </Card>
          <Card className="w-32 border-0 box-shadow">
            <Card.Body>
              <div className="d-flex justify-content-between">
                <h6 className="fw-normal mb-2">Total 2 users</h6>
                <ul className="list-unstyled d-flex align-items-center avatar-images mb-0">
                  <li>
                    <img
                      className="rounded-circle left-225"
                      src={avatar4}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="rounded-circle left-180"
                      src={avatar10}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="rounded-circle left-135"
                      src={avatar8}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="rounded-circle left-90"
                      src={avatar11}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="rounded-circle left-45"
                      src={avatar6}
                      alt=""
                    />
                  </li>
                </ul>
              </div>

              <div className="d-flex justify-content-between align-items-end mt-1">
                <div className="role-heading">
                  <h4 className="mb-1 heading-color">Restricted User</h4>
                  <a href="#">
                    <span>Edit Role</span>
                  </a>
                </div>
                <a href="#">
                  <LuCopy className="fs-4 heading-color" />
                </a>
              </div>
            </Card.Body>
          </Card>
          <Card className="w-32 border-0 box-shadow">
            <Card.Body>
              <div className="d-flex justify-content-between">
                <div className="user">
                  <img className="" src={User} alt="" />
                </div>
                <div className="d-flex flex-column">
                  <Button
                    variant="primary"
                    className="btn btn-primary mb-2 text-nowrap add-new-role"
                    onClick={handleButtonClick}
                  >
                    Add New Role
                  </Button>
                  <Modal size="lg" show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                      <div className="text-center">
                        <h3>Add New Role</h3>
                        <span>Set role permissions</span>
                      </div>
                      <form onSubmit={handleSubmit}>
                        <label className="mb-2">Role Name</label>
                        <input
                          className="form-control w-100 h-25"
                          placeholder="Enter a Role Name"
                          type="text"
                          value={searchText}
                          onChange={handleSearchInputChange}
                        />

                        <Modal.Footer className="justify-content-center">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                          <button
                            className="btn btn-secondary"
                            onClick={handleCloseModal}
                          >
                            Cancel
                          </button>
                        </Modal.Footer>

                        <div className="col-12">
                          <h5>Role Permissions</h5>
                          <div className="table-responsive">
                            <table className="table table-flush-spacing">
                              <tbody>
                                <tr>
                                  <td className="text-nowrap fw-medium d-flex align-items-center gap-47">
                                    <div className="heading">
                                      <h5>
                                        Administrator Access <CiCircleInfo />
                                      </h5>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={selectAll}
                                        onChange={handleSelectAll}
                                      />
                                      <label>Select All</label>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-nowrap fw-medium">
                                    User Management
                                  </td>
                                  <td>
                                    <div className="d-flex">
                                      <div className="form-check me-3 me-lg-5">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          checked={
                                            checkboxStates.userManagement.read
                                          }
                                          onChange={() =>
                                            handleCheckboxChange(
                                              "userManagement",
                                              "read"
                                            )
                                          }
                                        />
                                        <label>Read</label>
                                      </div>
                                      <div className="form-check me-3 me-lg-5">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          checked={
                                            checkboxStates.userManagement.write
                                          }
                                          onChange={() =>
                                            handleCheckboxChange(
                                              "userManagement",
                                              "write"
                                            )
                                          }
                                        />
                                        <label>Write</label>
                                      </div>
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          checked={
                                            checkboxStates.userManagement.create
                                          }
                                          onChange={() =>
                                            handleCheckboxChange(
                                              "userManagement",
                                              "create"
                                            )
                                          }
                                        />
                                        <label>Create</label>
                                      </div>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td className="text-nowrap fw-medium">
                                    Payroll
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <div className="form-check me-3 me-lg-5">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          checked={checkboxStates.payroll.read}
                                          onChange={() =>
                                            handleCheckboxChange(
                                              "payroll",
                                              "read"
                                            )
                                          }
                                        />
                                        <label>Read</label>
                                      </div>
                                      <div className="form-check me-3 me-lg-5">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          checked={checkboxStates.payroll.write}
                                          onChange={() =>
                                            handleCheckboxChange(
                                              "payroll",
                                              "write"
                                            )
                                          }
                                        />
                                        <label>Write</label>
                                      </div>
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          checked={
                                            checkboxStates.payroll.create
                                          }
                                          onChange={() =>
                                            handleCheckboxChange(
                                              "payroll",
                                              "create"
                                            )
                                          }
                                        />
                                        <label>Create</label>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </form>
                    </Modal.Body>
                  </Modal>
                  <p className="mb-0 mt-1">Add role, if it does not exist</p>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
      <RolesTable searchText={searchText} />
    </>
  )
}

export default RoleIndex
