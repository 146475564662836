import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import "../../../../../assets/css/AdminPages.css";
import ProtocolContext from "../../orgContext/protocol/ProtocolContext";
import LevelContext from "../../orgContext/level/LevelContext";
import MedicationContext from "../../orgContext/medication/MedicationContext";

/* RBT Components */
import { Accordion, Button, Card, Tabs } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";

/* icons */
import { FaPlus } from "react-icons/fa6";
import { SiOpslevel } from "react-icons/si";
import { RiChatDeleteLine } from "react-icons/ri";
import { MdFormatListBulletedAdd } from "react-icons/md";
import { MdOutlineRemoveCircle } from "react-icons/md";
import { BiSolidAddToQueue } from "react-icons/bi";
import { IoSave } from "react-icons/io5";

const OrgWizard = ({ title }) => {
  const {
    protocol,
    loading: protocolLoading,
    addWizardProtocol,
    editWizardProtocol,
    wizardProtocolEdit,
  } = useContext(ProtocolContext);
  const { level, loading } = useContext(LevelContext);
  const { loading: medicationLoading, medications } =
    useContext(MedicationContext);
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [medicationOptions, setMedicationOptions] = useState([]);
  const [isClearable, setIsClearable] = useState(true);
  const [selectedTab, setSelectedTab] = useState(null);
  const [showInnerModal, setShowInnerModal] = useState(false);
  const [tabsWithMedicine, setTabsWithMedicine] = useState([]);
  const [selectedMedication, setSelectedMedication] = useState();
  const [tabsWithFields, setTabsWithFields] = useState([]);
  const [tabMedicationData, setTabMedicationData] = useState({});
  const [selectedStep, setSelectedStep] = useState(null);
  const [activeKey, setActiveKey] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [levelNames, setLevelNames] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState(null);

  const params = useParams();

  const getProtocolName = () => {
    if (!protocolLoading && (!protocol || protocol.length === 0)) {
      return <p>No Protocol</p>;
    } else {
      const protocolName = protocol.find(
        (item) => item.protocol_id === params.id
      );
      return protocolName ? protocolName.name : "loading...";
    }
  };

  useEffect(() => {
    if (!loading) {
      setLevelNames(level.map((item) => item.name));
    }
  }, [level, loading]);

  useEffect(() => {
    editWizardProtocol(params.id);
  }, []);

  /* medication */
  useEffect(() => {
    if (medications && medications.length > 0) {
      const medicationNames = medications.map((medication) => medication.name);

      const options = medicationNames.map((medicationName) => ({
        value: medicationName,
        label: medicationName,
      }));

      setMedicationOptions(options);
    }
  }, [medications, medicationLoading]);

  /* handleLevels */

  const addLevelTab = (selectedLevelName, selectedLevelId) => {
    if (
      selectedLevelId &&
      !tabs.some((tab) => tab.title === `${selectedLevelName}`)
    ) {
      const newKey = `tab-${tabs.length + 1}`;
      const newTab = {
        key: newKey,
        title: `${selectedLevelName}`,
        tabId: newKey,
        steps: [],
      };
      console.log("my tab: ", newTab);
      setTabs((prevTabs) => [...prevTabs, newTab]);
      setActiveTab(newTab.tabId);
      // setTabs([...tabs, newTab])
      setActiveKey(newKey);
      setIsClearable(true);
    }

    setShowModal(false);
  };

  /* handleRemoveLevels */
  const removeTab = (tabId) => {
    setTabs((prevTabs) => {
      const updatedTabs = prevTabs.filter((tab) => tab.tabId !== tabId);
      console.log(updatedTabs);
      return updatedTabs;
    });

    // Set the active tab to the second-to-last tab
    if (tabs.length > 1) {
      setActiveTab(tabs[tabs.length - 2].tabId);
    } else {
      setActiveTab(null);
      setTabsWithMedicine([]);
      setTabMedicationData({});
      setTabsWithFields([]);
    }
  };

  /* handleAddSteps */
  const addStep = (tabId) => {
    setTabs((prevTabs) => {
      const updatedTabs = prevTabs.map((tab) =>
        tab.tabId === tabId
          ? {
              ...tab,
              steps: [...tab.steps, { stepNumber: tab.steps.length + 1 }],
            }
          : tab
      );
      return updatedTabs;
    });
  };

  const removeStep = (tabId, stepNumber) => {
    setTabs((prevTabs) => {
      const updatedTabs = prevTabs.map((tab) =>
        tab.tabId === tabId
          ? {
              ...tab,
              steps: tab.steps
                .filter((step) => step.stepNumber !== stepNumber)
                .map((step, index) => ({ ...step, stepNumber: index + 1 })),
            }
          : tab
      );

      const updatedFields = tabsWithFields.filter(
        (field) => !(field.tabId === tabId && field.stepNumber === stepNumber)
      );

      setTabsWithFields(updatedFields);
      return updatedTabs;
    });
  };

  /* handleAddFields */
  const handleAddFields = (tabId, stepNumber) => {
    setSelectedTab(tabId);
    setSelectedStep(stepNumber);
    setShowInnerModal(true);
  };

  const handleAddMedicine = (stepNumber) => {
    setTabsWithMedicine([
      ...tabsWithMedicine,
      { tabId: selectedTab, stepNumber },
    ]);
    setShowInnerModal(false);
  };

  const handleModalFields = (stepNumber, fieldType) => {
    setTabsWithFields([
      ...tabsWithFields,
      { tabId: selectedTab, stepNumber: stepNumber, fieldType },
    ]);
    setShowInnerModal(false);
  };

  const renderModalBody = (tabId, stepNumber) => {
    const hasCautionField = tabsWithFields.some(
      (field) =>
        field.tabId === tabId &&
        field.stepNumber === stepNumber &&
        field.fieldType === "Caution"
    );
    const hasInstructionField = tabsWithFields.some(
      (field) =>
        field.tabId === tabId &&
        field.stepNumber === stepNumber &&
        field.fieldType === "Instruction"
    );
    const hasMedicineField = tabsWithMedicine.some(
      (field) => field.tabId === tabId && field.stepNumber === stepNumber
    );
    return (
      <Form.Group>
        {!hasCautionField && stepNumber === selectedStep && (
          <Form.Check
            type="radio"
            label="Caution"
            onChange={() => handleModalFields(stepNumber, "Caution")}
          />
        )}
        {!hasInstructionField && stepNumber === selectedStep && (
          <Form.Check
            type="radio"
            label="Instruction"
            onChange={() => handleModalFields(stepNumber, "Instruction")}
          />
        )}
        {!hasMedicineField && stepNumber === selectedStep && (
          <Form.Check
            type="radio"
            label="Add Medicine"
            onChange={() => handleAddMedicine(stepNumber)}
          />
        )}
      </Form.Group>
    );
  };

  const handleRemoveField = (tabId, stepNumber, fieldType) => {
    if (fieldType === "Medicine") {
      setTabsWithMedicine((prevMedicineTabs) =>
        prevMedicineTabs.filter(
          (medicineTab) =>
            medicineTab.tabId !== tabId && medicineTab.stepNumber === stepNumber
        )
      );
      setTabMedicationData((prevData) => {
        const newData = { ...prevData };
        delete newData[tabId][stepNumber];
        if (Object.keys(newData[tabId]).length === 0) {
          delete newData[tabId];
        }
        return newData;
      });
    }
    setTabsWithFields((prevFields) => {
      const updatedFields = prevFields.filter(
        (field) =>
          !(
            field.tabId === tabId &&
            field.stepNumber === stepNumber &&
            field.fieldType === fieldType
          )
      );
      return updatedFields;
    });
  };

  const handleMedicationChange = (selectedOption, tabId, stepNumber) => {
    let getTabID = parseInt(tabId.replace("tab-", "")) - 1;
    console.log(
      "get length:",
      wizardProtocolEdit.item.tabsNumber[getTabID].steps[stepNumber - 1]
        .medicationData.name
    );

    if (
      wizardProtocolEdit.edit === true &&
      wizardProtocolEdit.item.tabsNumber[getTabID].steps[stepNumber - 1]
        .medicationData.name === selectedOption.label
    ) {
      const selectedData =
        wizardProtocolEdit.item.tabsNumber[getTabID].steps[stepNumber - 1]
          .medicationData;
      console.log("getTabID:", selectedData);
      setSelectedMedication({
        label: selectedData.name,
        value: selectedData.name,
      });
      setTabMedicationData((prevData) => ({
        ...prevData,
        [tabId]: {
          ...(prevData[tabId] || {}),
          [stepNumber]: selectedData,
        },
      }));
    } else {
      const selectedData = medications.find(
        (med) => med.name === selectedOption.label
      );
      setSelectedMedication({
        label: selectedData.name,
        value: selectedData.name,
      });
      setTabMedicationData((prevData) => ({
        ...prevData,
        [tabId]: {
          ...(prevData[tabId] || {}),
          [stepNumber]: selectedData,
        },
      }));
    }

    console.log("all medication: ", medications);
    // const selectedData = medications.find((med) => med.name === selectedOption.label)
    // console.log('selectedData: ', selectedData)
    /* setSelectedMedication({ label: selectedData.name, value: selectedData.name })
    setTabMedicationData((prevData) => ({
      ...prevData,
      [tabId]: {
        ...(prevData[tabId] || {}),
        [stepNumber]: selectedData,
      },
    })) */
  };

  const handleInputChange = (tabId, stepNumber, fieldType, value) => {
    setTabMedicationData((prevData) => ({
      ...prevData,
      [tabId]: {
        ...(prevData[tabId] || {}),
        [stepNumber]: {
          ...prevData[tabId]?.[stepNumber],
          protocols: [
            {
              ...prevData[tabId]?.[stepNumber]?.protocols?.[0],
              [fieldType]: value,
              doses: [
                {
                  ...prevData[tabId]?.[stepNumber]?.protocols?.[0]?.doses?.[0],
                  [fieldType]: value,
                },
              ],
            },
          ],
        },
      },
    }));
  };

  const handleFieldsInputChange = (tabId, stepNumber, fieldType, value) => {
    setTabsWithFields((prevFields) => {
      const updatedFields = prevFields.map((field) =>
        field.tabId === tabId &&
        field.stepNumber === stepNumber &&
        field.fieldType === fieldType
          ? { ...field, value: value }
          : field
      );
      return updatedFields;
    });
  };

  /* Modal */
  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  /* form */
  const handleSubmit = (e) => {
    e.preventDefault();

    // Gather all data
    const formData = {
      protocol_id: params.id,
      tabsNumber: tabs.map((tab) => ({
        level: tab.title,
        steps: tab.steps.map((step) => ({
          stepNumber: step.stepNumber,
          medicationData: tabMedicationData[tab.tabId]?.[step.stepNumber] || {},
          fields: tabsWithFields
            .filter(
              (field) =>
                field.tabId === tab.tabId &&
                field.stepNumber === step.stepNumber
            )
            .map((field) => ({ type: field.fieldType, value: field.value })),
        })),
      })),
    };
    addWizardProtocol(formData);
    console.log(formData);
  };
  /* latest */
  useEffect(() => {
    if (
      wizardProtocolEdit.edit === true &&
      wizardProtocolEdit.item !== undefined
    ) {
      // console.log('edit true', wizardProtocolEdit.item)
      const tabData = wizardProtocolEdit.item.tabsNumber.map((item, index) => {
        const oldKey = `tab-${index + 1}`;
        const oldTab = {
          key: oldKey,
          title: `${item.level}`,
          tabId: oldKey,
          steps: item.steps.map((step) => ({
            stepNumber: step.stepNumber,
            medicationData: step.medicationData,
            fields: step.fields,
          })),
        };
        return oldTab;
      });

      setTabs(tabData);
      setActiveTab(tabData.tabId);
      tabData.forEach((tab) => {
        tab.steps.forEach((step) => {
          step.fields.forEach((field) => {
            setTabsWithFields((prevState) => [
              ...prevState,
              {
                tabId: tab.tabId,
                stepNumber: step.stepNumber,
                fieldType: field.type,
              },
            ]);
            if (Object.keys(step.medicationData).length !== 0) {
              setTabsWithMedicine((prevState) => [
                ...prevState,
                { tabId: tab.tabId, stepNumber: step.stepNumber },
              ]);
              setSelectedMedication({
                label: step.medicationData.name,
                value: step.medicationData.name,
              });
              setTabMedicationData((prevData) => ({
                ...prevData,
                [tab.tabId]: {
                  ...(prevData[tab.tabId] || {}),
                  [step.stepNumber]: step.medicationData,
                },
              }));
            }
          });
        });
      });
      setActiveKey(0);
    }
  }, [wizardProtocolEdit]);

  console.log("tab:", tabMedicationData);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <h4 className="py-3 mb-4">{getProtocolName()}</h4>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Card border="0" bg="white" className="shadow d-flex flex-row">
            <Tab.Container
              id="left-tabs-example"
              activeKey={activeKey}
              onSelect={(key) => setActiveKey(key)}
            >
              <Card.Header className="protocol-wizard bg-white border-end rounded-end-0 border-bottom-0 border-secondary-subtle d-flex align-items-stretch justify-content-between flex-column p-4">
                <div className="p-types">
                  <Nav variant="pills" className="protocol-wizard-step active">
                    {tabs.length === 0 && <p>Click the "Add" to add Levels.</p>}
                    {tabs.map((tab, i) => (
                      <Nav.Item key={tab.key}>
                        <Nav.Link
                          className={`step-trigger d-flex flex-nowrap gap-3 fw-semibold align-items-center justify-content-center bg-transparent border-0`}
                          eventKey={tab.key}
                        >
                          <span className="step-trigger-circle">
                            <SiOpslevel size={25} />
                          </span>
                          <span className="step-trigger-label">
                            <span className="lh-1 stl-title">{tab.title}</span>
                            <span className="stl-subtitle">Any Text Here</span>
                          </span>
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => removeTab(tab.key)}
                            className="ml-2"
                          >
                            Remove
                          </Button>
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </div>
                <div className="p-add-more text-end">
                  <button
                    type="button"
                    className="btn btn-sm rounded-pill btn-icon btn-outline-primary mb-1"
                    onClick={handleShowModal}
                  >
                    <FaPlus size={20} />
                  </button>
                </div>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Tab.Content>
                    {tabs.map((tab) => (
                      <Tab.Pane eventKey={tab.key} key={tab.key}>
                        <Accordion>
                          {tab.steps.map((step) => (
                            <Card key={step.stepNumber} className="mb-3">
                              <Accordion.Item
                                eventKey={step.stepNumber.toString()}
                              >
                                <Accordion.Header>{`Step ${step.stepNumber}`}</Accordion.Header>
                                <Accordion.Body>
                                  {wizardProtocolEdit.edit && <p>hi</p>}
                                  {tabsWithMedicine.some(
                                    (field) =>
                                      field.tabId === tab.tabId &&
                                      field.stepNumber === step.stepNumber
                                  ) && (
                                    <Select
                                      className="basic-single"
                                      classNamePrefix="select"
                                      isClearable={isClearable}
                                      options={medicationOptions}
                                      onChange={(selectedOption) =>
                                        handleMedicationChange(
                                          selectedOption,
                                          tab.tabId,
                                          step.stepNumber
                                        )
                                      }
                                      value={medicationOptions.find(
                                        (option) =>
                                          option.value ===
                                          selectedMedication.value
                                      )}
                                    />
                                  )}
                                  {tabMedicationData[tab.tabId]?.[
                                    step.stepNumber
                                  ] && (
                                    <>
                                      <Row className="mb-3">
                                        <Form.Group as={Col}>
                                          <Form.Label>Adult Dose</Form.Label>
                                          <Form.Control
                                            type="text"
                                            placeholder="Enter Adult Dose"
                                            value={
                                              tabMedicationData[tab.tabId]?.[
                                                step.stepNumber
                                              ]?.protocols?.[0]?.doses?.[0]
                                                ?.adult || ""
                                            }
                                            onChange={(e) =>
                                              handleInputChange(
                                                tab.tabId,
                                                step.stepNumber,
                                                "adult",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                          <Form.Label>
                                            Pediatric Dose
                                          </Form.Label>
                                          <Form.Control
                                            type="text"
                                            placeholder="Enter Pediatric Dose"
                                            value={
                                              tabMedicationData[tab.tabId]?.[
                                                step.stepNumber
                                              ]?.protocols[0]?.doses[0]
                                                ?.pediatric || ""
                                            }
                                            onChange={(e) =>
                                              handleInputChange(
                                                tab.tabId,
                                                step.stepNumber,
                                                "pediatric",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </Form.Group>
                                      </Row>
                                      <Row className="mb-3">
                                        <Form.Group as={Col}>
                                          <Form.Label>
                                            Repeat Interval
                                          </Form.Label>
                                          <Form.Control
                                            type="text"
                                            placeholder="Enter Repeat Interval"
                                            value={
                                              tabMedicationData[tab.tabId]?.[
                                                step.stepNumber
                                              ]?.protocols[0]?.repeat_interval
                                            }
                                            onChange={(e) =>
                                              handleInputChange(
                                                tab.tabId,
                                                step.stepNumber,
                                                "repeat_interval",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                          <Form.Label>Maximum Dose</Form.Label>
                                          <Form.Control
                                            type="text"
                                            placeholder="Enter Maximum Dose"
                                            value={
                                              tabMedicationData[tab.tabId]?.[
                                                step.stepNumber
                                              ]?.protocols[0]?.maximum_dose
                                            }
                                            onChange={(e) =>
                                              handleInputChange(
                                                tab.tabId,
                                                step.stepNumber,
                                                "maximum_dose",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </Form.Group>
                                      </Row>
                                      <Row className="mb-3">
                                        <Form.Group
                                          controlId={`instruction_${tab}`}
                                        >
                                          <Form.Label>Instruction</Form.Label>
                                          <Form.Control
                                            type="text"
                                            placeholder="Enter instruction"
                                            value={
                                              tabMedicationData[tab.tabId]?.[
                                                step.stepNumber
                                              ]?.protocols[0]?.instructions
                                            }
                                            onChange={(e) =>
                                              handleInputChange(
                                                tab.tabId,
                                                step.stepNumber,
                                                "instructions",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                          <Form.Label>Routes</Form.Label>
                                          <Form.Control
                                            type="text"
                                            placeholder="Enter Routes"
                                            value={
                                              tabMedicationData[tab.tabId]?.[
                                                step.stepNumber
                                              ]?.protocols[0]?.routes
                                            }
                                            onChange={(e) =>
                                              handleInputChange(
                                                tab.tabId,
                                                step.stepNumber,
                                                "routes",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </Form.Group>
                                        <Button
                                          variant="primary"
                                          onClick={() =>
                                            handleRemoveField(
                                              tab.tabId,
                                              step.stepNumber,
                                              "Medicine"
                                            )
                                          }
                                        >
                                          Remove Medicine
                                        </Button>
                                      </Row>
                                    </>
                                  )}
                                  {tabsWithFields.some(
                                    (field) =>
                                      field.tabId === tab.tabId &&
                                      field.stepNumber === step.stepNumber &&
                                      field.fieldType === "Caution"
                                  ) && (
                                    <>
                                      <Form.Group className="mb-3">
                                        <Form.Label>Caution</Form.Label>
                                        <Form.Control
                                          type="text"
                                          placeholder="Enter Caution"
                                          value={
                                            tabsWithFields.find(
                                              (field) =>
                                                field.tabId === tab.tabId &&
                                                field.stepNumber ===
                                                  step.stepNumber &&
                                                field.fieldType === "Caution"
                                            )?.value || ""
                                          }
                                          onChange={(e) =>
                                            handleFieldsInputChange(
                                              tab.tabId,
                                              step.stepNumber,
                                              "Caution",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Form.Group>
                                      <Button
                                        variant="danger"
                                        onClick={() =>
                                          handleRemoveField(
                                            tab.tabId,
                                            step.stepNumber,
                                            "Caution"
                                          )
                                        }
                                      >
                                        <RiChatDeleteLine size={25} />
                                        <span className="ps-3">Caution</span>
                                      </Button>
                                    </>
                                  )}
                                  {tabsWithFields.some(
                                    (field) =>
                                      field.tabId === tab.tabId &&
                                      field.stepNumber === step.stepNumber &&
                                      field.fieldType === "Instruction"
                                  ) && (
                                    <>
                                      <Form.Group className="mb-3">
                                        <Form.Label>Instruction</Form.Label>
                                        <Form.Control
                                          type="text"
                                          placeholder="Enter Instruction"
                                          value={
                                            tabsWithFields.find(
                                              (field) =>
                                                field.tabId === tab.tabId &&
                                                field.stepNumber ===
                                                  step.stepNumber &&
                                                field.fieldType ===
                                                  "Instruction"
                                            )?.value || ""
                                          }
                                          onChange={(e) =>
                                            handleFieldsInputChange(
                                              tab.tabId,
                                              step.stepNumber,
                                              "Instruction",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Form.Group>
                                      <Button
                                        onClick={() =>
                                          handleRemoveField(
                                            tab.tabId,
                                            step.stepNumber,
                                            "Instruction"
                                          )
                                        }
                                      >
                                        Remove Instruction
                                      </Button>
                                    </>
                                  )}
                                  <p>
                                    this is your {tab.tabId} and step number is{" "}
                                    {step.stepNumber}
                                  </p>
                                  <div className="d-flex justify-content-between align-content-center">
                                    <Button
                                      className="btn-bg me-3"
                                      onClick={() =>
                                        handleAddFields(
                                          tab.tabId,
                                          step.stepNumber
                                        )
                                      }
                                    >
                                      <MdFormatListBulletedAdd size={25} />
                                      <span className="ps-2">Fields</span>
                                    </Button>
                                    <Button
                                      variant="danger"
                                      onClick={() =>
                                        removeStep(tab.tabId, step.stepNumber)
                                      }
                                    >
                                      <MdOutlineRemoveCircle size={25} />
                                      <span className="ps-2">Step</span>
                                    </Button>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                              <Modal
                                show={
                                  showInnerModal &&
                                  selectedTab === tab.tabId &&
                                  selectedStep === step.stepNumber
                                }
                                onHide={() => setShowInnerModal(false)}
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title>Select Option</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  {renderModalBody(tab.tabId, step.stepNumber)}
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button
                                    variant="secondary"
                                    onClick={() => setShowInnerModal(false)}
                                  >
                                    Close
                                  </Button>
                                </Modal.Footer>
                              </Modal>
                            </Card>
                          ))}
                        </Accordion>
                        <Button
                          className="btn-add"
                          onClick={() => addStep(tab.tabId)}
                        >
                          <BiSolidAddToQueue size={25} />
                          <span className="ps-2">Step</span>
                        </Button>
                      </Tab.Pane>
                    ))}
                    {tabs.length !== 0 && (
                      <Button
                        className="btn-save position-absolute"
                        onClick={handleSubmit}
                      >
                        <IoSave size={20} />
                        <span className="ps-2">Save</span>
                      </Button>
                    )}
                  </Tab.Content>
                  {/* Level Selection Modal */}
                  <Modal
                    show={showModal}
                    onHide={handleCloseModal}
                    onShow={() => setSelectedLevel(null)}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Select Level</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={isClearable}
                        options={levelNames
                          .filter(
                            (levelName) =>
                              !tabs.some((tab) => tab.title === `${levelName}`)
                          )
                          .map((filteredLevel) => ({
                            label: filteredLevel,
                            value: level.find((p) => p.name === filteredLevel)
                              ?.level_id,
                          }))}
                        value={selectedLevel}
                        onChange={(selectedOption) =>
                          setSelectedLevel(selectedOption)
                        }
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="primary"
                        onClick={() =>
                          addLevelTab(selectedLevel.label, selectedLevel.value)
                        }
                      >
                        Add Level
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </Form>
              </Card.Body>
            </Tab.Container>
          </Card>
        </div>
      </div>
    </>
  );
};
export default OrgWizard;
