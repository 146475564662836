import { Link } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import "primereact/resources/themes/lara-light-cyan/theme.css"
import "../../../../../assets/css/Datatable.css"
import "../../../../../assets/css/AdminPages.css"
import UserContext from '../../orgContext/user/UserContext'
import Spinner from '../../../../shared/Spinner'

/* RBT Components */
import { Button } from 'react-bootstrap'
import Offcanvas from 'react-bootstrap/Offcanvas'
import Form from 'react-bootstrap/Form'

/* icons */
import { TiEdit } from "react-icons/ti"
import { MdOutlineDeleteSweep } from "react-icons/md"
import { FaPlus } from "react-icons/fa6"
import { LuEye } from "react-icons/lu"
import { Password } from 'primereact/password'



const OrgUserIndex = ({ title, ...props }) => {
  const { user, userEdit, loading, addUser, editUser, updateUser, deleteUser } = useContext(UserContext)

  const [show, setShow] = useState(false)

  const handleClose = () => {
    setUserFormData({
      name: '',
      email: '',
      password: ''
    })
    setShow(false)
  }

  const handleShow = () => {
    setShow(true)
  }

  const [userFormData, setUserFormData] = useState({
    name: '',
    email: '',
    password: ''
  })

  useEffect(() => {
    if (userEdit.edit === true) {
      setShow(true)
      setUserFormData({
        name: userEdit.item.name || '',
        email: userEdit.item.email || '',
      })
    }
  }, [userEdit])

  const handleTextChange = (e) => {
    setUserFormData({
      ...userFormData,
      [e.target.name]: e.target.value,
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const newUser = {
      name: e.target.name.value,
      email: e.target.email.value,
      password: e.target.password.value,
      role: 'organizationUser',
      permissions: {
        canViewContent: false,
        canAddContent: true,
        canEditContent: true,
        canDeleteContent: false
      }
    }
    if (userEdit.edit === true) {
      console.log(userEdit)
      updateUser(userEdit.item.id, newUser)
    } else {
      addUser(newUser)
    }
    setUserFormData({
      name: '',
      email: '',
      password: ''
    })
    setShow(false)
  }

  const verifiedBodyTemplate = (dataItem) => {
    return (
      <div className="d-flex align-items-center gap-2">
        <Link to={`/organization/module/user/medical-protocols/${dataItem.id}`} className='cta-icons'>
          <LuEye size={30} color='darkGrey' />
        </Link>
        <button className='cta-icons border-0 bg-transparent' onClick={() => editUser(dataItem)}>
          <TiEdit size={30} color='darkGrey' />
        </button>
        <button href="#" className='cta-icons border-0 bg-transparent'>
          <MdOutlineDeleteSweep size={30} color='darkGrey' onClick={() => deleteUser(dataItem.user_id)} />
        </button>
      </div>
    )
  }

  if (!loading && (!user || user.length === 0)) {
    return <p>No User</p>
  }
  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h1>{title}</h1>
        <Button variant="primary" onClick={handleShow} className='d-flex align-items-center gap-2'>
          <FaPlus />
          Add New User
        </Button>
      </div>
      <Offcanvas show={show} onHide={handleClose} placement="end" {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add User</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="UserName">
              <Form.Label>User Name</Form.Label>
              <Form.Control type="text" placeholder="Enter User Name" name='name' value={userFormData.name} onChange={handleTextChange} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="UserName">
              <Form.Label>User email</Form.Label>
              <Form.Control type="text" placeholder="Enter Email" name='email' value={userFormData.email} onChange={handleTextChange} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="UserName">
              <Form.Label>User Password</Form.Label>
              <Form.Control type="text" placeholder="Enter Password" name='password' onChange={handleTextChange} />
            </Form.Group>
            <Button variant="primary" type="submit">
              {userEdit.edit ? 'Update User' : 'Add User'}
            </Button>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
      <div className="row">
        <div className="col-md-12">
          <div className="card bg-white shadow border-0">
            <div className="card-body p-0">
              {loading ? (
                <Spinner />
              ) : (
                <DataTable value={user ?? []} paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}>
                  <Column field="name" header="Name"></Column>
                  <Column field="email" header="Email"></Column>
                  <Column field="action" header="Action" dataType="boolean" style={{ minWidth: '6rem' }} body={verifiedBodyTemplate} />
                </DataTable>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default OrgUserIndex