import { useState } from "react";
import { Card, Button, Container, Row, Col, Form } from "react-bootstrap";

import avatar1 from "../../../../../assets/images/Roles/Avatar_9.png";

function Account() {
  const initialData = {
    firstName: "John",
    lastName: "Doe",
    email: "john.doe@example.com",
    organization: "Pixinvent",
    number: "",
    address: "",
    state: "California",
    zipCode: "231465",
    country: "",
    language: "",
    timezone: "",
    currency: "",
  };

  const [formData, setFormData] = useState(initialData);

  const handleFormChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  return (
    <div>
      <Row className="justify-content-center rounded">
        <Col xs={12}>
          <Card className="profile-card rounded p-4 bg-white shadow-sm rounded ">
            <h5>Profile Details</h5>
            <Card.Body className="d-flex align-items-start align-items-sm-center gap-4">
              <img
                src={avatar1}
                alt="user-avatar"
                className="d-block w-px-100 h-px-100 rounded"
                id="uploadedAvatar"
              />
              <div className="">
                <label htmlFor="upload" className="btn btn-primary me-2 mb-3 ">
                  <span className="d-none d-sm-block">Upload new photo</span>
                  <input
                    type="file"
                    id="upload"
                    className="account-file-input"
                    hidden
                    accept="image/png, image/jpeg"
                  />
                </label>
                <button type="button" class="btn btn-secondary  mb-3 ">
                  <span class="d-none d-sm-block">Reset</span>
                </button>

                <div className="text-muted">
                  Allowed JPG, GIF or PNG. Max size of 800K
                </div>
              </div>
            </Card.Body>
            <Card.Body>
              <Row className="align-items-start align-items-sm-center gap-4">
                <Col>
                  <Form>
                    <Row>
                      <Col md={6} className="mb-3">
                        <Form.Label htmlFor="firstName">First Name</Form.Label>
                        <Form.Control
                          type="text"
                          id="firstName"
                          name="firstName"
                          value={formData.firstName}
                          onChange={(e) =>
                            handleFormChange("firstName", e.target.value)
                          }
                        />
                      </Col>
                      <Col md={6} className="mb-3">
                        <Form.Label htmlFor="lastName">Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          id="lastName"
                          name="lastName"
                          value={formData.lastName}
                          onChange={(e) =>
                            handleFormChange("lastName", e.target.value)
                          }
                        />
                      </Col>
                      <Col md={6} className="mb-3">
                        <Form.Label htmlFor="email">E-mail</Form.Label>
                        <Form.Control
                          type="text"
                          id="email"
                          name="email"
                          value={formData.email}
                          onChange={(e) =>
                            handleFormChange("email", e.target.value)
                          }
                          placeholder="john.doe@example.com"
                        />
                      </Col>
                      <Col md={6} className="mb-3">
                        <Form.Label htmlFor="organization">
                          Organization
                        </Form.Label>
                        <Form.Control
                          type="text"
                          id="organization"
                          name="organization"
                          value={formData.organization}
                          onChange={(e) =>
                            handleFormChange("organization", e.target.value)
                          }
                        />
                      </Col>
                      <Col md={6} className="mb-3">
                        <Form.Label htmlFor="phoneNumber">
                          Phone Number
                        </Form.Label>
                        <div className="input-group input-group-merge">
                          <span className="input-group-text">US (+1)</span>
                          <Form.Control
                            type="text"
                            id="phoneNumber"
                            name="phoneNumber"
                            className="form-control"
                            placeholder="202 555 0111"
                            value={formData.number}
                            onChange={(e) =>
                              handleFormChange("number", e.target.value)
                            }
                          />
                        </div>
                      </Col>
                      <Col md={6} className="mb-3">
                        <Form.Label htmlFor="address">Address</Form.Label>
                        <Form.Control
                          type="text"
                          id="address"
                          name="address"
                          placeholder="Address"
                          value={formData.address}
                          onChange={(e) =>
                            handleFormChange("address", e.target.value)
                          }
                        />
                      </Col>
                      <Col md={6} className="mb-3">
                        <Form.Label htmlFor="state">State</Form.Label>
                        <Form.Control
                          type="text"
                          id="state"
                          name="state"
                          placeholder="California"
                          value={formData.state}
                          onChange={(e) =>
                            handleFormChange("state", e.target.value)
                          }
                        />
                      </Col>
                      <Col md={6} className="mb-3">
                        <Form.Label htmlFor="zipCode">Zip Code</Form.Label>
                        <Form.Control
                          type="text"
                          id="zipCode"
                          name="zipCode"
                          placeholder="231465"
                          maxLength="6"
                          value={formData.zipCode}
                          onChange={(e) =>
                            handleFormChange("zipCode", e.target.value)
                          }
                        />
                      </Col>
                      <Col md={6} className="mb-3">
                        <Form.Label htmlFor="country">Country</Form.Label>
                        <Form.Select
                          id="country"
                          name="country"
                          value={formData.country}
                          onChange={(e) =>
                            handleFormChange("country", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="Australia">Australia</option>
                          <option value="Bangladesh">Bangladesh</option>
                          <option value="Belarus">Belarus</option>
                          <option value="Brazil">Brazil</option>
                          <option value="Canada">Canada</option>
                          <option value="China">China</option>
                          <option value="France">France</option>
                          <option value="Germany">Germany</option>
                          <option value="India">India</option>
                          <option value="Indonesia">Indonesia</option>
                          <option value="Israel">Israel</option>
                          <option value="Italy">Italy</option>
                          <option value="Japan">Japan</option>
                          <option value="Korea">Korea, Republic of</option>
                          <option value="Mexico">Mexico</option>
                          <option value="Philippines">Philippines</option>
                          <option value="Russia">Russian Federation</option>
                          <option value="South Africa">South Africa</option>
                          <option value="Thailand">Thailand</option>
                          <option value="Turkey">Turkey</option>
                          <option value="Ukraine">Ukraine</option>
                          <option value="United Arab Emirates">
                            United Arab Emirates
                          </option>
                          <option value="United Kingdom">United Kingdom</option>
                          <option value="United States">United States</option>
                        </Form.Select>
                      </Col>
                      <Col md={6} className="mb-3">
                        <Form.Label htmlFor="language">Language</Form.Label>
                        <Form.Select
                          id="language"
                          name="language"
                          value={formData.language}
                          onChange={(e) =>
                            handleFormChange("language", e.target.value)
                          }
                        >
                          <option value="">Select Language</option>
                          <option value="en">English</option>
                          <option value="fr">French</option>
                          <option value="de">German</option>
                          <option value="pt">Portuguese</option>
                        </Form.Select>
                      </Col>
                      <Col md={6} className="mb-3">
                        <Form.Label htmlFor="timeZones">Timezone</Form.Label>
                        <Form.Select
                          id="timeZones"
                          name="timeZones"
                          value={formData.timezone}
                          onChange={(e) =>
                            handleFormChange("timezone", e.target.value)
                          }
                        >
                          <option value="">Select Timezone</option>
                          <option value="-12">
                            (GMT-12:00) International Date Line West
                          </option>
                          <option value="-11">
                            (GMT-11:00) Midway Island, Samoa
                          </option>
                          <option value="-10">(GMT-10:00) Hawaii</option>
                          <option value="-9">(GMT-09:00) Alaska</option>
                          <option value="-8">
                            (GMT-08:00) Pacific Time (US & Canada)
                          </option>
                          <option value="-8">
                            (GMT-08:00) Tijuana, Baja California
                          </option>
                          <option value="-7">(GMT-07:00) Arizona</option>
                          <option value="-7">
                            (GMT-07:00) Chihuahua, La Paz, Mazatlan
                          </option>
                          <option value="-7">
                            (GMT-07:00) Mountain Time (US & Canada)
                          </option>
                          <option value="-6">
                            (GMT-06:00) Central America
                          </option>
                          <option value="-6">
                            (GMT-06:00) Central Time (US & Canada)
                          </option>
                          <option value="-6">
                            (GMT-06:00) Guadalajara, Mexico City, Monterrey
                          </option>
                          <option value="-6">(GMT-06:00) Saskatchewan</option>
                          <option value="-5">
                            (GMT-05:00) Bogota, Lima, Quito, Rio Branco
                          </option>
                          <option value="-5">
                            (GMT-05:00) Eastern Time (US & Canada)
                          </option>
                          <option value="-5">(GMT-05:00) Indiana (East)</option>
                          <option value="-4">
                            (GMT-04:00) Atlantic Time (Canada)
                          </option>
                          <option value="-4">
                            (GMT-04:00) Caracas, La Paz
                          </option>
                        </Form.Select>
                      </Col>
                      <Col md={6} className="mb-3">
                        <Form.Label htmlFor="currency">Currency</Form.Label>
                        <Form.Select
                          id="currency"
                          name="currency"
                          value={formData.currency}
                          onChange={(e) =>
                            handleFormChange("currency", e.target.value)
                          }
                        >
                          <option value="">Select Currency</option>
                          <option value="usd">USD</option>
                          <option value="euro">Euro</option>
                          <option value="pound">Pound</option>
                          <option value="bitcoin">Bitcoin</option>
                        </Form.Select>
                      </Col>
                    </Row>
                    <div className="mt-2">
                      <Button variant="primary" className="me-2" type="submit">
                        Save Changes
                      </Button>
                      <Button variant="secondary" type="reset">
                        Cancel
                      </Button>
                    </div>
                  </Form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Account;
