import { useEffect } from "react"
/* RBT Components */
import { Card } from 'react-bootstrap'

/* icons */
import { FaLaptopMedical } from "react-icons/fa"
import { FaHouseMedicalCircleXmark } from "react-icons/fa6"
import { GiBellShield } from "react-icons/gi"
import { Link } from 'react-router-dom'

const OrgDashboard = ({ title }) => {
  useEffect(() => {
    document.title = `${title} - MedicSnow`;
  }, [title])
  return (
    <div className="main-admin">
      <div className="dashboard">
        <div className="container-fluid p-5">
          <h1>MedicSnow Organization Dashboard</h1>
          <Card border='0' bg='light' className='shadow px-3 my-3'>
            <Card.Title className="my-3 fw-semibold">
              Available Modules
            </Card.Title>
            <div className="row mb-5 row-gap-4">
              <div className="col-md-3">
                <Card border='0' bg='body-secondary' className="shadow">
                  <Card.Body>
                    <div className="d-flex flex-column gap-3 text-center align-items-center justify-content-around">
                      <div className="bg-primary rounded-4 p-3">
                        <FaLaptopMedical size={35} color='white' />
                      </div>
                      <div className="dashModuleBody">
                        <Card.Title>
                          Medic Module
                        </Card.Title>
                        <Link className='card-link' to={`/organization/categories/index`}>View Module</Link>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </Card>
          <Card border='0' bg='light' className='shadow px-3 my-3'>
            <Card.Title className="my-3 fw-semibold">
              Purchase Modules
            </Card.Title>
            <div className="row mb-5 row-gap-4">
              <div className="col-md-3">
                <Card border='0' bg='body-secondary' className="shadow">
                  <Card.Body>
                    <div className="d-flex flex-column gap-3 text-center align-items-center justify-content-around">
                      <div className="bg-primary rounded-4 p-3">
                        <FaHouseMedicalCircleXmark size={35} color='white' />
                      </div>
                      <div className="dashModuleBody">
                        <Card.Title>
                          Assets Module
                        </Card.Title>
                        <Card.Link href="#">View Module</Card.Link>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-md-3">
                <Card border='0' bg='body-secondary' className="shadow">
                  <Card.Body>
                    <div className="d-flex flex-column gap-3 text-center align-items-center justify-content-around">
                      <div className="bg-primary rounded-4 p-3">
                        <GiBellShield size={35} color='white' />
                      </div>
                      <div className="dashModuleBody">
                        <Card.Title>
                          ER Module
                        </Card.Title>
                        <Card.Link href="#">View Module</Card.Link>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default OrgDashboard