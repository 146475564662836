import { Link } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import "primereact/resources/themes/lara-light-cyan/theme.css"
import "../../../../../assets/css/Datatable.css"
import "../../../../../assets/css/AdminPages.css"
import ProtocolContext from '../../orgContext/protocol/ProtocolContext'
import CategoryContext from '../../orgContext/category/CategoryContext'
import Spinner from '../../../../shared/Spinner'
import Select from 'react-select'

/* RBT Components */
import { Button } from 'react-bootstrap'
import Offcanvas from 'react-bootstrap/Offcanvas'
import Form from 'react-bootstrap/Form'

/* icons */
import { TiEdit } from "react-icons/ti"
import { MdLibraryAdd, MdOutlineDeleteSweep } from "react-icons/md"
import { FaPlus } from "react-icons/fa6"
import { LuEye } from "react-icons/lu"

const OrgProtocolsIndex = ({ title, ...props }) => {
  const { protocol, protocolWizard, protocolEdit, loading, addProtocol, editProtocol, updateProtocol, deleteProtocol } = useContext(ProtocolContext)
  console.log('context protocol:', protocolWizard)
  const { category } = useContext(CategoryContext)

  const [isClearable, setIsClearable] = useState(true)
  const [show, setShow] = useState(false)

  const handleClose = () => {
    setProtocolFormData({
      name: '',
      description: '',
      categoryId: '',
    })
    setShow(false)
  }

  const handleShow = () => {
    setShow(true)
  }

  const [protocolFormData, setProtocolFormData] = useState({
    name: '',
    description: '',
  })

  useEffect(() => {
    if (protocolEdit.edit === true) {
      setShow(true)
      setProtocolFormData({
        name: protocolEdit.item.name || '',
        description: protocolEdit.item.description || '',
        categoryId: protocolEdit.item.categoryId || '',
      })
    }
  }, [protocolEdit])

  const handleTextChange = (e) => {
    setProtocolFormData({
      ...protocolFormData,
      [e.target.name]: e.target.value,
    })
  }

  const handleSelectChange = (selectedOption) => {
    setProtocolFormData({
      ...protocolFormData,
      categoryId: selectedOption ? selectedOption.value : '',
    })
  }

  const categoryOptions = category.map((item) => ({
    value: item.id,
    label: item.name,
  }))


  // console.log('form data: ', protocolFormData)

  const handleSubmit = (e) => {
    e.preventDefault()
    const newProtocol = {
      name: e.target.name.value,
      description: e.target.description.value,
      categoryId: protocolFormData.categoryId,
      organization_id: '1c0d6294-23f9-479a-a9a8-6eb840524c22'
    }
    if (protocolEdit.edit === true) {
      updateProtocol(protocolEdit.item.protocol_id, newProtocol)
    } else {
      addProtocol(newProtocol)
    }
    setProtocolFormData({
      name: '',
      description: '',
      categoryId: '',
    })
    setShow(false)
  }

  const getCategoryName = (dataItem) => {
    const categoryData = category.find((item) => item.category_id === dataItem.category_id)
    return categoryData ? categoryData.name : "Unknown Category";
  }

  const verifiedBodyTemplate = (dataItem) => {
    const isProtocolIdMatch = protocolWizard.some(protocol => protocol.protocol_id === dataItem.protocol_id);
    console.log(isProtocolIdMatch)
    return (
      <div className="d-flex align-items-center gap-2">
        {isProtocolIdMatch ? (
          <Link to={`/organization/protocol/wizard/${dataItem.protocol_id}`} className='cta-icons'>
            <LuEye size={30} color='darkGrey' />
          </Link>
        ) : (
          <Link to={`/organization/protocol/wizard/${dataItem.protocol_id}`} className='cta-icons'>
            <MdLibraryAdd size={30} color='darkGrey' />
          </Link>
        )}
        <button className='cta-icons border-0 bg-transparent' onClick={() => editProtocol(dataItem)}>
          <TiEdit size={30} color='darkGrey' />
        </button>
        <button href="#" className='cta-icons border-0 bg-transparent'>
          <MdOutlineDeleteSweep size={30} color='darkGrey' onClick={() => deleteProtocol(dataItem.protocol_id)} />
        </button>
      </div>
    )
  }

  if (!loading && (!protocol || protocol.length === 0)) {
    return <p>No Protocol</p>
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h1>{title}</h1>
        <Button variant="primary" onClick={handleShow} className='d-flex align-items-center gap-2'>
          <FaPlus />
          Add New Protocol
        </Button>
      </div>
      <Offcanvas show={show} onHide={handleClose} placement="end" {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Protocol</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="ProtocolName">
              <Form.Label>Protocol Name</Form.Label>
              <Form.Control type="text" placeholder="Enter Protocol Name" name='name' value={protocolFormData.name} onChange={handleTextChange} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="ProtocolName">
              <Form.Label>Protocol Description</Form.Label>
              <Form.Control type="text" placeholder="Enter Protocol Description" name='description' value={protocolFormData.description} onChange={handleTextChange} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="categoryName">
              <Form.Label>Category</Form.Label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={isClearable}
                options={categoryOptions}
                onChange={handleSelectChange}
                value={categoryOptions.find(option => option.value === protocolFormData.category_id)}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              {protocolFormData.name ? 'Update Protocol' : 'Add Protocol'}
            </Button>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
      <div className="row">
        <div className="col-md-12">
          <div className="card bg-white shadow border-0">
            <div className="card-body p-0">
              {loading ? (
                <Spinner />
              ) : (
                <DataTable value={protocol} paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}>
                  <Column field="name" header="Name"></Column>
                  <Column field="description" header="Description"></Column>
                  <Column field="category_id" header="Category" body={getCategoryName}>
                  </Column>
                  <Column field="action" header="Action" dataType="boolean" style={{ minWidth: '6rem' }} body={verifiedBodyTemplate} />
                </DataTable>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default OrgProtocolsIndex