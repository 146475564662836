const myData = [
  {
    id: '1',
    name: 'Organization 1',
    description: 'Product Description',
    email: 'bamboo-watch.jpg',
    status: 'Active',
  },
  {
    id: '2',
    name: 'Organization 2',
    description: 'Product Description',
    email: 'bamboo-watch.jpg',
    status: 'Active',
  }
]

export default myData