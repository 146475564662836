import { Link } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import "primereact/resources/themes/lara-light-cyan/theme.css"
import "../../../../../assets/css/Datatable.css"
import "../../../../../assets/css/AdminPages.css"
import CategoryContext from '../../orgContext/category/CategoryContext'
import Spinner from '../../../../shared/Spinner'

/* RBT Components */
import { Button } from 'react-bootstrap'
import Offcanvas from 'react-bootstrap/Offcanvas'
import Form from 'react-bootstrap/Form'

/* icons */
import { TiEdit } from "react-icons/ti"
import { MdOutlineDeleteSweep } from "react-icons/md"
import { FaPlus } from "react-icons/fa6"
import { LuEye } from "react-icons/lu"


const OrgCategoriesIndex = ({ title, ...props }) => {
  const { category, categoryEdit, loading, addCategory, editCategory, updateCategory, deleteCategory } = useContext(CategoryContext)
  const [show, setShow] = useState(false)

  const handleClose = () => {
    setCategoryFormData({
      name: '',
      description: '',
    })
    setShow(false)
  }

  const handleShow = () => {
    setShow(true)
  }

  const [categoryFormData, setCategoryFormData] = useState({
    name: '',
    description: '',
  })

  useEffect(() => {
    if (categoryEdit.edit === true) {
      setShow(true)
      setCategoryFormData({
        name: categoryEdit.item.name || '',
        description: categoryEdit.item.description || '',
      })
    }
  }, [categoryEdit])

  const handleTextChange = (e) => {
    setCategoryFormData({
      ...categoryFormData,
      [e.target.name]: e.target.value,
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const newCategory = {
      name: e.target.name.value,
      description: e.target.description.value,
      organization_id: '58feec74-a5b4-4e97-95b7-1cff074b81c1'
    }
    if (categoryEdit.edit === true) {
      updateCategory(categoryEdit.item.category_id, newCategory)
    } else {
      addCategory(newCategory)
    }
    setCategoryFormData({
      name: '',
      description: '',
    })
    setShow(false)
  }

  const verifiedBodyTemplate = (dataItem) => {
    return (
      <div className="d-flex align-items-center gap-2">
        <Link to={`/organization/module/category/medical-protocols/${dataItem.id}`} className='cta-icons'>
          <LuEye size={30} color='darkGrey' />
        </Link>
        <button className='cta-icons border-0 bg-transparent' onClick={() => editCategory(dataItem)}>
          <TiEdit size={30} color='darkGrey' />
        </button>
        <button href="#" className='cta-icons border-0 bg-transparent'>
          <MdOutlineDeleteSweep size={30} color='darkGrey' onClick={() => deleteCategory(dataItem.category_id)} />
        </button>
      </div>
    )
  }

  if (!loading && (!category || category.length === 0)) {
    return <p>No Category</p>
  }
  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h1>{title}</h1>
        <Button variant="primary" onClick={handleShow} className='d-flex align-items-center gap-2'>
          <FaPlus />
          Add New Category
        </Button>
      </div>
      <Offcanvas show={show} onHide={handleClose} placement="end" {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Category</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="CategoryName">
              <Form.Label>Category Name</Form.Label>
              <Form.Control type="text" placeholder="Enter Category Name" name='name' value={categoryFormData.name} onChange={handleTextChange} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="CategoryName">
              <Form.Label>Category Description</Form.Label>
              <Form.Control type="text" placeholder="Enter Description" name='description' value={categoryFormData.description} onChange={handleTextChange} />
            </Form.Group>
            <Button variant="primary" type="submit">
              {categoryEdit.edit ? 'Update Category' : 'Add Category'}
            </Button>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
      <div className="row">
        <div className="col-md-12">
          <div className="card bg-white shadow border-0">
            <div className="card-body p-0">
              {loading ? (
                <Spinner />
              ) : (
                <DataTable value={category ?? []} paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}>
                  <Column field="name" header="Name"></Column>
                  <Column field="description" header="Description"></Column>
                  <Column field="action" header="Action" dataType="boolean" style={{ minWidth: '6rem' }} body={verifiedBodyTemplate} />
                </DataTable>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default OrgCategoriesIndex