/* css */
import "primereact/resources/themes/lara-light-cyan/theme.css"
import "../../../../assets/css/Datatable.css"
import "../../../../assets/css/AdminPages.css"

/* RBT Components */
import { Card, Button, Form } from 'react-bootstrap'

/* icons */
import { FaPlus } from "react-icons/fa6"


const OrgModulesEdit = () => {


  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h1>Edit Module</h1>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Card border='0' bg='body-secondary' className="shadow">
            <Card.Body>
              <Form>
                <Form.Group className="mb-3" controlId="organizationName">
                  <Form.Label>Organization Name</Form.Label>
                  <Form.Control type="email" placeholder="Enter Organization Name" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="emailAddress">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" placeholder="Enter email" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" placeholder="Password" />
                </Form.Group>
                <Button type="submit" variant="primary" className='d-flex align-items-center gap-2'>
                  <FaPlus />
                  Update Module
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  )
}
export default OrgModulesEdit