import { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "./App.css";
import {
  Login,
  MainApp,
  Dashboard,
  OrganizationIndex,
  OrganizationCreate,
  OrganizationEdit,
  ModulesIndex,
  ModulesCreate,
  ModulesEdit,
  OrgDashboard,
  OrgModulesIndex,
  OrgModulesEdit,
  OrgModulesCreate,
  OrgAdditionalModulesIndex,
  OrgUserCreate,
  UserEdit,
  OrgUserIndex,
  OrgCategoryCreate,
  OrgCategoriesIndex,
  OrgProtocolsIndex,
  OrgProtocolsCreate,
  OrgMedicineIndex,
  OrgMedicineCreate,
  OrgMedicineEdit,
  OrgWizard,
  OrgLevelCreate,
  OrgLevelIndex,
  RoleIndex,
  Account,
  ChangePassword,
  PaymentPlan,
  OrgUserLogin,
  UnAuthorized,
} from "./components";
import { CategoryProvider } from "./components/pages/organization/orgContext/category/CategoryContext";
import { MedicationProvider } from "./components/pages/organization/orgContext/medication/MedicationContext";
import { LevelProvider } from "./components/pages/organization/orgContext/level/LevelContext";
import { ProtocolProvider } from "./components/pages/organization/orgContext/protocol/ProtocolContext";
import { UserProvider } from "./components/pages/organization/orgContext/user/UserContext";

function App() {
  const permissionsString = localStorage.getItem("permissions");
  const permissionsObject = permissionsString
    ? JSON.parse(permissionsString)
    : null;
  const [auth, setAuth] = useState({
    token: localStorage.getItem("token") === "true",
    role: localStorage.getItem("role"),
    permissions: permissionsObject,
  });

  const isAuthenticated = auth.token;
  // const isAdminUsers = auth.role === "adminusers";
  const isOrganizationUsers = auth.role === "organizationUser";
  const isOrganization = auth.role === "organizations";
  const canViewContent = auth.permissions && auth.permissions.canViewContent;
  // const canViewContent = auth.permissions.canViewContent;

  const orgUserRoutes = (
    <>
      <Route
        exact
        path="/organization/dashboard"
        element={
          <>
            <OrgDashboard title="Organization Dashboard" />
          </>
        }
      />
      <Route
        exact
        path="/organization/modules"
        element={
          <>
            <MainApp title="List Modules" Component={OrgModulesIndex} />
          </>
        }
      />
      {/* Organization Category */}
      <Route
        exact
        path="/organization/categories/index"
        element={
          <>
            <MainApp
              title="Medic Module Categories"
              Component={OrgCategoriesIndex}
            />
          </>
        }
      />
      <Route
        exact
        path="/organization/categories/create"
        element={
          <>
            <MainApp
              title="Medic Create Category"
              Component={OrgCategoryCreate}
            />
          </>
        }
      />
      {/* Organization Medicine */}
      <Route
        exact
        path="/organization/medicine/index"
        element={
          <MainApp title="Medic Medicine List" Component={OrgMedicineIndex} />
        }
      />
      <Route
        exact
        path="/organization/medicine/create"
        element={
          <MainApp
            title="Medic Medicine Create"
            Component={OrgMedicineCreate}
          />
        }
      />
      <Route
        exact
        path="/organization/medicine/edit/:id"
        element={
          <MainApp title="Medic Medicine Edit" Component={OrgMedicineEdit} />
        }
      />
      {/* Protocols */}
      <Route
        exact
        path="/organization/protocol/index"
        element={
          <>
            <MainApp title="Protocols List" Component={OrgProtocolsIndex} />
          </>
        }
      />
      <Route
        exact
        path="/organization/protocol/create"
        element={
          <>
            <MainApp title="Create Protocol" Component={OrgProtocolsCreate} />
          </>
        }
      />
      <Route
        exact
        path="/organization/protocol/wizard/:id"
        element={
          <>
            <MainApp title="Protocol Wizard" Component={OrgWizard} />
          </>
        }
      />
      {/* Levels */}
      <Route
        exact
        path="/organization/level/index"
        element={
          <>
            <MainApp title="Levels List" Component={OrgLevelIndex} />
          </>
        }
      />
      <Route
        exact
        path="/organization/level/create"
        element={
          <>
            <MainApp title="Create Level" Component={OrgLevelCreate} />
          </>
        }
      />
      {/* Module Categories */}
      <Route
        exact
        path="/organization/category/:moduleName/create"
        element={
          <>
            <MainApp title="Create Category" Component={OrgCategoryCreate} />
          </>
        }
      />
      <Route
        exact
        path="/organization/additional-modules-lists"
        element={
          <>
            <MainApp
              title="Additional Modules"
              Component={OrgAdditionalModulesIndex}
            />
          </>
        }
      />
      <Route
        exact
        path="/organization/modules/create"
        element={
          <>
            <MainApp title="Add Modules" Component={OrgModulesCreate} />
          </>
        }
      />
      <Route
        exact
        path="/organization/modules/edit/:id"
        element={
          <>
            <MainApp title="Edit Modules" Component={OrgModulesEdit} />
          </>
        }
      />
      {/* Create Org User */}
      <Route
        exact
        path="/organization/users/create"
        element={
          <>
            <MainApp title="Create User" Component={OrgUserCreate} />
          </>
        }
      />
      <Route
        exact
        path="/organization/users/edit/:id"
        element={
          <>
            <MainApp title="Create User" Component={UserEdit} />
          </>
        }
      />
      <Route
        exact
        path="/organization/setting/users"
        element={
          <>
            <MainApp title="List Users" Component={OrgUserIndex} />
          </>
        }
      />
      <Route
        exact
        path="/organization/protocol"
        element={
          <>
            <MainApp title="List Users" Component={OrgProtocolsCreate} />
          </>
        }
      />
      {/* Roles & Permissions */}
      <Route
        exact
        path="/organization/settings/roles"
        element={
          <>
            <MainApp title="User Roles" Component={RoleIndex} />
          </>
        }
      />

      {/* Account Setting */}
      <Route
        exact
        path="/organization/setting/profile"
        element={
          <>
            <MainApp title="Profile" Component={Account} />
          </>
        }
      />

      {canViewContent && (
        <Route
          exact
          path="/organization/setting/changepassword"
          element={
            <>
              <MainApp title="Change Password" Component={ChangePassword} />
            </>
          }
        />
      )}

      <Route
        exact
        path="/organization/setting/paymentplan"
        element={
          <>
            <MainApp title="Payment Plan" Component={PaymentPlan} />
          </>
        }
      />
    </>
  );
  const organizationRoutes = <>{orgUserRoutes}</>;

  return (
    <>
      <Router>
        {/* Admin Routes */}
        <Routes>
          <Route path="/admin/login" element={<Login />} />
          <Route
            path="/admin/dashboard"
            element={
              <>
                <MainApp title="Dashboard" Component={Dashboard} />
              </>
            }
          />
          {/* Organization */}
          <Route
            path="/admin/organization"
            element={
              <>
                <MainApp
                  title="Organizations List"
                  Component={OrganizationIndex}
                />
              </>
            }
          />
          <Route
            path="/admin/organization/create"
            element={
              <>
                <MainApp
                  title="Add Organization"
                  Component={OrganizationCreate}
                />
              </>
            }
          />
          <Route
            path="/admin/organization/edit/:id"
            element={
              <>
                <MainApp
                  title="Edit Organization"
                  Component={OrganizationEdit}
                />
              </>
            }
          />
          {/* Modules */}
          <Route
            path="/admin/modules"
            element={
              <>
                <MainApp title="Modules List" Component={ModulesIndex} />
              </>
            }
          />
          <Route
            path="/admin/modules/create"
            element={
              <>
                <MainApp title="Add Module" Component={ModulesCreate} />
              </>
            }
          />
          <Route
            path="/admin/modules/edit/:id"
            element={
              <>
                <MainApp title="Edit Module" Component={ModulesEdit} />
              </>
            }
          />
        </Routes>
        {/* Organization Routes */}
        <CategoryProvider>
          <ProtocolProvider>
            <MedicationProvider>
              <LevelProvider>
                <UserProvider>
                  <Routes>
                    {/* For UnAuthorized user */}
                    {!isAuthenticated && (
                      <Route
                        path="*"
                        element={<Navigate to="/organization/login" />}
                      />
                    )}
                    <Route exact path="/organization/login" element={<Login />} />
                    <Route
                      exact
                      path="/organization_user/login"
                      element={<OrgUserLogin />}
                    />
                    {isOrganization && organizationRoutes}
                    {isOrganizationUsers && orgUserRoutes}
                    <Route
                      path="*"
                      element={
                        <>
                          <MainApp title="Edit Module" Component={UnAuthorized} />
                        </>
                      }
                    />
                  </Routes>
                </UserProvider>
              </LevelProvider>
            </MedicationProvider>
          </ProtocolProvider>
        </CategoryProvider>
      </Router>
    </>
  );
}

export default App;
