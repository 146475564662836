const medicationReducer = (state, action) => {
  switch (action.type) {
    case 'SET_MEDICATION':
      return {
        ...state,
        medications: action.payload,
        loading: false,
      }
    case 'GET_MEDICATION':
      return {
        ...state,
        medication: action.payload,
        loading: false,
      }
    case 'ADD_MEDICATION':
      return {
        ...state,
        medication: [action.payload, ...state.medication],
      }
    case 'DELETE_MEDICATION':
      return {
        ...state,
        medication: state.medication.filter((item) => item.id !== action.payload),
      }
    case 'UPDATE_MEDICATION':
      const { id, updatedData } = action.payload;
      const updatedMedication = {
        [id]: { ...state.medication[id], ...updatedData }
      };
      return {
        ...state,
        medication: updatedMedication,
      };
    case 'EDIT_MEDICATION':
      return {
        ...state,
        medicationEdit: {
          item: action.payload,
          edit: true,
        },
      }
    default:
      return state
  }
}

export default medicationReducer